/* DataTable.css */
.data-table-container {
    margin-top: 20px;
    border: 1px solid #ddd;
    border-radius: 8px;
    overflow-x: auto;
    background-color: #fff;
  }
  
  .data-table {
    width: 100%;
    border-collapse: collapse;
    font-family: Arial, sans-serif;
  }
  
  .data-table th,
  .data-table td {
    border: 1px solid #e1e1e1;
    padding: 8px 12px;
    text-align: center;
  }
  
  .data-table th {
    background-color: #f4f4f4;
    font-weight: bold;
    text-transform: capitalize;
  }
  
  .data-table tbody tr:nth-child(even) {
    background-color: #fafafa;
  }
  
  .data-table tbody tr:hover {
    background-color: #f1f1f1;
  }
  
  .data-table-empty {
    text-align: center;
    margin-top: 20px;
    font-size: 14px;
    color: #666;
    padding: 20px;
  }
  