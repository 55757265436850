/* General Styling */
.manage-team {
  font-family: 'Segoe UI', Tahoma, Geneva, Verdana, sans-serif;
  max-width: 1200px;
  margin: 0 auto;
  padding: 20px;
  background-color: #f9f9fb;
  color: #333;
  border-radius: 10px;
  box-shadow: 0 2px 10px rgba(0, 0, 0, 0.1);
}

.manage-team h1 {
  text-align: center;
  font-size: 2.5rem;
  margin-bottom: 20px;
  color: #007bff;
}

/* Search Bar: Aligned Left */
.search-bar {
  display: flex;
  gap: 10px;
  flex-wrap: nowrap;
  margin-bottom: 20px;
  justify-content: flex-start; /* Aligns search bar and button to the left */
}

.search-bar input {
  flex: 0 0 300px; /* Fixed width for input */
  padding: 10px;
  border: 1px solid #ccc;
  border-radius: 8px;
  font-size: 1rem;
}

.search-bar button {
  padding: 10px 15px;
  background-color: #007bff;
  color: white;
  border: none;
  border-radius: 8px;
  cursor: pointer;
  font-size: 1rem;
  transition: background-color 0.3s;
}

.search-bar button:hover {
  background-color: #0056b3;
}

/* Form Styling */
.team-form {
  display: grid;
  grid-template-columns: 1fr 1fr;
  gap: 20px;
  background: white;
  padding: 30px;
  border-radius: 12px;
  box-shadow: 0 6px 10px rgba(0, 0, 0, 0.1);
  margin-bottom: 20px;
}

.team-form input {
  padding: 10px;
  border: 1px solid #ddd;
  border-radius: 8px;
  font-size: 1rem;
  transition: border-color 0.3s;
}

.team-form input:focus {
  border-color: #007bff;
  outline: none;
}

.team-form button {
  padding: 10px 15px;
  border: none;
  background-color: #28a745;
  color: white;
  border-radius: 8px;
  font-size: 1rem;
  font-weight: bold;
  cursor: pointer;
  grid-column: span 2;
  justify-self: center;
  width: 150px;
  transition: background-color 0.3s;
}

.team-form button:hover {
  background-color: #218838;
}

.team-form .cancel-btn {
  background-color: #dc3545;
  justify-self: center;
  width: 150px;
}

.team-form .cancel-btn:hover {
  background-color: #c82333;
}

.image-preview {
  grid-column: span 2;
  width: 120px;
  height: 120px;
  object-fit: cover;
  border: 2px solid #007bff;
  border-radius: 10px;
  margin: 10px auto;
}

/* Table Styling */
.team-table {
  width: 100%;
  border-collapse: collapse;
  background-color: white;
  border-radius: 10px;
  overflow: hidden;
  box-shadow: 0 4px 6px rgba(0, 0, 0, 0.1);
}

.team-table th,
.team-table td {
  padding: 12px;
  text-align: center;
  border: 1px solid #ddd;
  font-size: 0.9rem;
}

.team-table th {
  background-color: #007bff;
  color: white;
}

.team-table tr:nth-child(even) {
  background-color: #f9f9f9;
}

.team-table tr:hover {
  background-color: #e6f7ff;
}

.table-image {
  width: 50px;
  height: 50px;
  object-fit: cover;
  border-radius: 50%;
}

/* Action Buttons: Same Size */
.action-buttons {
  display: flex;
  justify-content: center;
  gap: 8px;
}

.action-buttons button {
  padding: 6px 12px;
  border: none;
  border-radius: 6px;
  font-size: 0.85rem;
  cursor: pointer;
  transition: background-color 0.3s;
  width: 80px; /* Fixed width for both buttons */
  text-align: center;
}

.action-buttons .edit-button {
  background-color: #ffc107;
  color: white;
}

.action-buttons .edit-button:hover {
  background-color: #e0a800;
}

.action-buttons .delete-button {
  background-color: #dc3545;
  color: white;
}

.action-buttons .delete-button:hover {
  background-color: #c82333;
}

/* Pagination */
.pagination {
  display: flex;
  justify-content: center;
  align-items: center;
  gap: 10px;
  margin-top: 20px;
}

.pagination button {
  padding: 8px 15px;
  background-color: #007bff;
  color: white;
  border: none;
  border-radius: 8px;
  font-size: 0.9rem;
  cursor: pointer;
  transition: background-color 0.3s;
}

.pagination button:disabled {
  background-color: #ccc;
  cursor: not-allowed;
}

.pagination button:hover:not(:disabled) {
  background-color: #0056b3;
}

/* Responsive Design */
@media (max-width: 768px) {
  .team-form {
    grid-template-columns: 1fr;
  }

  .team-form button {
    grid-column: span 1;
  }

  .team-table th,
  .team-table td {
    font-size: 0.85rem;
    padding: 8px;
  }

  .team-table img {
    width: 40px;
    height: 40px;
  }
}

@media (max-width: 480px) {
  .search-bar {
    flex-direction: column;
  }

  .search-bar input,
  .search-bar button {
    width: 100%;
  }

  .team-form input,
  .team-form button {
    font-size: 0.9rem;
    padding: 8px;
  }

  .team-table th,
  .team-table td {
    font-size: 0.8rem;
    padding: 6px;
  }

  .pagination button {
    font-size: 0.8rem;
    padding: 5px 8px;
  }

  .action-buttons button {
    width: 70px;
    font-size: 0.8rem;
  }
}
