/* Prevent Right Click, Inspect & Refresh */
body, html {
    user-select: none;
    pointer-events: auto;
}

input, button, textarea {
    pointer-events: auto;
}

img {
    pointer-events: none;
}

/* Full Page Container */
.merchantpay-container {
    display: flex;
    justify-content: center;
    align-items: center;
    height: 100vh;
    background: linear-gradient(to right, #f8f9fa, #e9ecef);
}

/* Payment Box */
.merchantpay-box {
    width: 420px;
    background: #fff;
    padding: 25px;
    border-radius: 12px;
    box-shadow: 0px 5px 15px rgba(0, 0, 0, 0.2);
    text-align: center;
    animation: fadeIn 0.5s ease-in-out;
}

/* Fade In Animation */
@keyframes fadeIn {
    from { opacity: 0; transform: scale(0.9); }
    to { opacity: 1; transform: scale(1); }
}

.merchantpay-box h1 {
    font-size: 28px;
    font-weight: 700;
    margin-bottom: 12px;
    color: #333;
}

.merchantpay-box h3 {
    font-size: 20px;
    font-weight: 600;
    color: #ff4b5c;
    margin-bottom: 15px;
}

/* Course Payment Summary */
.payment-summary {
    background: #fff3cd;
    padding: 15px;
    border-radius: 8px;
    text-align: left;
    font-size: 16px;
    box-shadow: inset 0px 0px 8px rgba(0, 0, 0, 0.05);
    margin-bottom: 15px;
    font-weight: bold;
}

.payment-summary p {
    display: flex;
    justify-content: space-between;
    padding: 8px 0;
    border-bottom: 1px solid #ddd;
}

.payment-summary p span {
    color: #ff4b5c;
    font-weight: bold;
}

/* Total Amount Highlight */
.total-amount {
    background: #ffeb3b;
    padding: 10px;
    border-radius: 6px;
    font-size: 18px;
    font-weight: bold;
    text-align: center;
}

/* Discount Input */
.discount-container {
    display: flex;
    justify-content: space-between;
    margin: 5px 0;
}

.discount-input {
    width: 35%;
   
    border: 2px solid #ddd;
    border-radius: 6px;
    font-size: 14px;
    transition: all 0.3s ease-in-out;
    background: #f8f9fa;
}

.discount-input:focus {
    border-color: #3498db;
    background: #ffffff;
    outline: none;
    box-shadow: 0px 0px 5px rgba(52, 152, 219, 0.5);
}

.apply-btn {
    width: 25%;
    padding: 2px;
    background: linear-gradient(145deg, #3498db, #2980b9);
    color: white;
    border: none;
    cursor: pointer;
    transition: 0.3s;
    border-radius: 6px;
    font-size: 14px;
    font-weight: bold;
}

.apply-btn:disabled {
    background: #ccc;
    cursor: not-allowed;
}

.apply-btn:hover {
    background: linear-gradient(145deg, #2980b9, #3498db);
    transform: translateY(-2px);
}

/* Input Fields */
input[type="text"],
input[type="email"],
input[type="tel"] {
    width: 100%;
  
    margin: 10px 0;
    border: 2px solid #ddd;
    border-radius: 8px;
    font-size: 14px;
    background: #f8f9fa;
    transition: all 0.3s ease-in-out;
}

input[type="text"]:focus,
input[type="email"]:focus,
input[type="tel"]:focus {
    border-color: #3498db;
    background: #ffffff;
    outline: none;
    box-shadow: 0px 0px 5px rgba(52, 152, 219, 0.5);
}

/* Pay Button - Red */
.pay-btn {
    width: 100%;
    padding: 12px;
    background: linear-gradient(145deg, #e74c3c, #c0392b);
    color: white;
    font-size: 18px;
    border: none;
    border-radius: 8px;
    cursor: pointer;
    margin-top: 10px;
    transition: 0.3s;
    font-weight: bold;
    text-transform: uppercase;
    box-shadow: 0px 4px 10px rgba(231, 76, 60, 0.3);
}

.pay-btn:disabled {
    background: #ccc;
    cursor: not-allowed;
}

.pay-btn:hover {
    background: linear-gradient(145deg, #c0392b, #e74c3c);
    box-shadow: 0px 6px 12px rgba(231, 76, 60, 0.5);
    transform: translateY(-2px);
}

/* Loader Overlay */
.full-page-loader {
    position: fixed;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    background: rgba(255, 255, 255, 0.9);
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    font-size: 18px;
    font-weight: bold;
    color: #333;
}

/* Loader Animation */
.loader {
    border: 6px solid #f3f3f3;
    border-top: 6px solid #ff4b5c;
    border-radius: 50%;
    width: 45px;
    height: 45px;
    animation: spin 1s linear infinite;
    margin-bottom: 10px;
}

@keyframes spin {
    0% { transform: rotate(0deg); }
    100% { transform: rotate(360deg); }
}

/* Payment Success Popup */
.payment-success-popup {
    position: fixed;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
    width: 380px;
    background: white;
    padding: 25px;
    text-align: center;
    border-radius: 12px;
    box-shadow: 0px 5px 15px rgba(0, 0, 0, 0.2);
    animation: fadeIn 0.5s ease-in-out;
}

.payment-success-popup h2 {
    color: #28a745;
    font-size: 22px;
    margin-bottom: 10px;
}

.payment-success-popup p {
    font-size: 14px;
    margin: 5px 0;
    font-weight: bold;
    color: #333;
}

.payment-success-popup strong {
    color: #ff4b5c;
}

/* Back Button */
.back-btn {
    background: none;
    border: none;
    color: #007bff;
    font-size: 16px;
    cursor: pointer;
    transition: 0.3s;
    margin-bottom: 10px;
}

.back-btn:hover {
    color: #0056b3;
}
