/* BannerSection.css */
.banner-section {
    padding: 60px 20px;
    background: linear-gradient(to right, #007bff, #00c6ff);
    color: white;
    text-align: center;
  }
  
  .banner-content {
    display: flex;
    align-items: center;
    justify-content: space-between;
    gap: 20px;
    flex-wrap: wrap;
  }
  
  .banner-text {
    flex: 1;
    text-align: left;
    max-width: 600px;
  }
  
  .banner-text h1 {
    font-size: 2.5rem;
    margin-bottom: 15px;
    font-weight: bold;
  }
  
  .banner-text p {
    font-size: 1.1rem;
    margin-bottom: 20px;
    line-height: 1.6;
  }
  
  .banner-highlights {
    margin: 20px 0;
    padding-left: 20px;
  }
  
  .banner-highlights li {
    font-size: 1rem;
    margin-bottom: 10px;
    list-style: none;
  }
  
  .banner-highlights li::before {
    content: "✔ ";
    color: #ffdd57;
    font-weight: bold;
  }
  
  .banner-button {
    background: #ff6b6b;
    color: white;
    border: none;
    padding: 10px 20px;
    font-size: 1rem;
    border-radius: 5px;
    cursor: pointer;
    transition: background 0.3s ease, transform 0.2s ease;
  }
  
  .banner-button:hover {
    background: #e04e4e;
    transform: scale(1.05);
  }
  
  .banner-image {
    flex: 1;
    text-align: center;
   
  }
  
  .banner-image img {
    width: 100%;
    border-radius: 10px;
    box-shadow: 0 4px 10px rgba(0, 0, 0, 0.2);
  }
  
  /* Responsive Design */
  @media (max-width: 768px) {
    .banner-content {
      flex-direction: column;
      text-align: center;
    }
  
    .banner-text {
      text-align: center;
    }
  
    .banner-text h1 {
      font-size: 2rem;
    }
  
    .banner-text p {
      font-size: 1rem;
    }
  
    .banner-button {
      font-size: 0.9rem;
    }
  }
  
  @media (max-width: 480px) {
    .banner-text h1 {
      font-size: 1.8rem;
    }
  
    .banner-text p {
      font-size: 0.9rem;
    }
  
    .banner-highlights li {
      font-size: 0.9rem;
    }
  
    .banner-button {
      font-size: 0.8rem;
      padding: 8px 16px;
    }
  
    .banner-image {
      max-width: 90%;
    }
  }