/* ========================= */
/* General Container Styles */
/* ========================= */
.categories-container {
    padding: 20px;
    font-family: 'Arial', sans-serif;
  }
  
  /* ========================= */
  /* Header Section */
  /* ========================= */
  .header {
    display: flex;
    justify-content: space-between;
    align-items: center;
    margin-bottom: 20px;
    flex-wrap: wrap;
  }
  
  /* Search Bar - Positioned on the Left */
  .search-bar {
    display: flex;
    align-items: center;
    gap: 10px;
    flex: 1;
  }
  
  .search-bar input {
    flex: 1;
    padding: 10px;
    border-radius: 4px;
    border: 1px solid #ccc;
    max-width: 300px;
  }
  
  .search-btn {
    background-color: #4caf50;
    color: white;
    padding: 10px 14px;
    border-radius: 4px;
    font-size: 14px;
    border: none;
    cursor: pointer;
  }
  
  /* Add Button - Positioned to the Top Right Corner */
  .add-btn {
    background-color: #f39c12;
    color: white;
    padding: 8px 12px;
    border-radius: 4px;
    font-size: 14px;
    border: none;
    cursor: pointer;
    margin-left: 20px;
  }
  
  /* ========================= */
  /* Form Modal Styles */
  /* ========================= */
  .form-modal-overlay {
    position: fixed;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    background: rgba(0, 0, 0, 0.5);
    display: flex;
    justify-content: center;
    align-items: center;
    z-index: 1000;
  }
  
  .form-modal {
    background: white;
    padding: 30px;
    border-radius: 8px;
    width: 400px;
    max-width: 90%;
    box-shadow: 0 4px 8px rgba(0, 0, 0, 0.2);
  }
  
  /* Form Header */
  .form-header {
    display: flex;
    justify-content: space-between;
    align-items: center;
    margin-bottom: 20px;
  }
  
  .form-header h3 {
    margin: 0;
  }
  
  .form-header .close-btn {
    background: transparent;
    border: none;
    font-size: 20px;
    cursor: pointer;
  }
  
  /* Form Fields */
  label {
    font-weight: bold;
    margin-top: 15px;
    display: block;
  }
  
  input,
  textarea {
  
    margin-top: 5px;
    margin-bottom: 15px;
    padding: 10px;
    border-radius: 4px;
    border: 1px solid #ccc;
    box-sizing: border-box;
  }
  
  textarea {
    resize: none;
    height: 80px;
  }
  
  /* Form Buttons */
  .form-buttons {
    display: flex;
    gap: 10px;
    margin-top: 15px;
  }
  
  .submit-btn {
    background-color: #0078d7;
    color: white;
    padding: 10px 15px;
    border-radius: 4px;
    border: none;
    cursor: pointer;
  }
  
  .cancel-btn {
    background-color: #ff4757;
    color: white;
    padding: 10px 15px;
    border-radius: 4px;
    border: none;
    cursor: pointer;
  }
  
  /* ========================= */
  /* Category Cards */
  /* ========================= */
  .category-cards {
    display: grid;
    grid-template-columns: repeat(auto-fit, minmax(250px, 1fr));
    gap: 15px;
  }
  
  .category-card {
    background: #fff;
    border: 1px solid #ddd;
    padding: 15px;
    border-radius: 8px;
    text-align: center;
    box-shadow: 0 2px 5px rgba(0, 0, 0, 0.1);
  }
  
  .category-card h3 {
    margin: 0;
    font-size: 18px;
    color: #333;
  }
  
  .category-card p {
    font-size: 14px;
    color: #666;
    margin: 10px 0;
  }
  
  .card-actions {
    margin-top: 10px;
    display: flex;
    justify-content: center;
    gap: 10px;
  }
  
  .card-actions .edit-btn {
    background: #ffc107;
    color: white;
    padding: 6px 10px;
    border-radius: 4px;
    font-size: 12px;
    border: none;
    cursor: pointer;
  }
  
  .card-actions .delete-btn {
    background: #dc3545;
    color: white;
    padding: 6px 10px;
    border-radius: 4px;
    font-size: 12px;
    border: none;
    cursor: pointer;
  }
  
  /* ========================= */
  /* Responsive Design */
  /* ========================= */
  
  /* Tablet View */
  @media (max-width: 768px) {
    .header {
      flex-direction: column;
      align-items: flex-start;
    }
  
    .search-bar {
      width: 100%;
      margin-bottom: 10px;
    }
  
    .add-btn {
      margin-left: 0;
    }
  
    .form-modal {
      width: 90%;
    }
  }
  
  /* Mobile View */
  @media (max-width: 480px) {
    .search-bar {
      flex-direction: column;
      align-items: stretch;
    }
  
    .search-bar input {
      width: 100%;
    }
  
    .form-modal {
      width: 95%;
    }
  }
  