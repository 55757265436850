/* General Styles */
.contact-us {
  font-family: "Roboto", sans-serif;
  background-color: #f9f9f9;
  padding: 20px;
}

/* ========== Banner Section ========== */
.banner {
  position: relative;
  width: 100%;
  height: 300px;
  overflow: hidden;
}

.banner-image {
  width: 100%;
  height: 100%;
  object-fit: cover;
}

.banner-overlay {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background: rgba(0, 0, 0, 0.5);
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  text-align: center;
  color: white;
}

.banner-overlay h1 {
  font-size: 2.5rem;
  margin-bottom: 10px;
}

.banner-overlay p {
  font-size: 1.2rem;
}

/* ========== Contact Container ========== */
.contact-container {
  display: flex;
  flex-wrap: wrap;
  gap: 20px;
  margin: 30px 0;
  justify-content: center;
}

.contact-details,
.contact-form,
.chat-container {
  flex: 1;
  max-width: 400px;
  background: #ffffff;
  padding: 20px;
  border-radius: 10px;
  box-shadow: 0px 4px 8px rgba(0, 0, 0, 0.1);
}

/* ========== Contact Details ========== */
.contact-details h2,
.contact-form h2 {
  font-size: 1.8rem;
  color: #007bff;
  margin-bottom: 15px;
}

.contact-details p {
  font-size: 1rem;
  margin: 10px 0;
}

.contact-details .icon {
  margin-right: 10px;
  color: #007bff;
}

.contact-details .clickable {
  color: #007bff;
  text-decoration: none;
  transition: color 0.3s ease;
}

.contact-details .clickable:hover {
  color: #0056b3;
  text-decoration: underline;
}

/* Social Links */
.social-links {
  display: flex;
  gap: 15px;
  margin-top: 20px;
}

.social-icon {
  font-size: 1.5rem;
  color: #007bff;
  transition: transform 0.3s ease, color 0.3s ease;
}

.social-icon:hover {
  transform: scale(1.2);
  color: #0056b3;
}

/* Google Map */
.map-container {
  margin-top: 20px;
}

.map-container iframe {
  border: 0;
  width: 100%;
  height: 200px;
  border-radius: 10px;
}

/* ========== Contact Form ========== */
.contact-form form {
  display: flex;
  flex-direction: column;
  gap: 15px;
}

.contact-form input,
.contact-form textarea {
  width: 100%;
  padding: 10px;
  border: 1px solid #ccc;
  border-radius: 5px;
  font-size: 1rem;
  transition: border-color 0.3s ease, box-shadow 0.3s ease;
}

.contact-form input:focus,
.contact-form textarea:focus {
  border-color: #007bff;
  outline: none;
  box-shadow: 0px 0px 5px rgba(0, 123, 255, 0.5);
}

.contact-form textarea {
  resize: vertical;
}

/* Send Message Button */
.btn-submit {
  background: linear-gradient(to right, #007bff, #0056b3);
  color: white;
  font-size: 1.1rem;
  font-weight: bold;
  border: none;
  border-radius: 8px;
  padding: 12px 20px;
  box-shadow: 0 4px 6px rgba(0, 0, 0, 0.2);
  transition: all 0.3s ease;
  cursor: pointer;
  text-transform: uppercase;
}

.btn-submit:hover {
  background: linear-gradient(to right, #0056b3, #007bff);
  transform: scale(1.05);
  box-shadow: 0 6px 8px rgba(0, 0, 0, 0.3);
}

.response-message {
  font-size: 1rem;
  color: green;
  margin-top: 10px;
}

/* ===== Chat Section ===== */
.chat-container {
  max-width: 500px;
  background: #fff;
  border-radius: 8px;
  box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1);
  overflow: hidden;
  font-family: Arial, sans-serif;
}

/* Chat Title */
.chat-title {
  background: linear-gradient(to right, #007bff, #0056b3);
  color: white;
  padding: 10px;
  text-align: center;
  font-size: 1.2rem;
  font-weight: bold;
  text-transform: uppercase;
}

/* Chat Window */
.chat-window {
  padding: 15px;
  max-height: 350px;
  overflow-y: auto;
  background: #f9fafc;
}

/* Chat Message */
.chat-message {
  display: flex;
  align-items: flex-start;
  margin-bottom: 10px;
}

.chat-message.user {
  flex-direction: row-reverse;
}

.chat-avatar {
  font-size: 1.2rem;
  margin: 0 8px;
  color: #007bff;
}

.chat-bubble {
  background: #e9ecef;
  border-radius: 10px;
  padding: 8px 12px;
  max-width: 75%;
  font-size: 0.9rem;
  word-wrap: break-word;
}

.chat-message.user .chat-bubble {
  background: #007bff;
  color: white;
}

/* Typing Indicator */
.chat-typing-indicator {
  font-style: italic;
  color: #888;
  margin: 10px 0;
  font-size: 0.85rem;
}

/* Quick Replies */
.quick-replies {
  display: flex;
  flex-wrap: wrap;
  gap: 8px;
  padding: 10px 15px;
  background: #f1f1f1;
}

.quick-reply {
  background: #007bff;
  color: white;
  border: none;
  padding: 6px 10px;
  border-radius: 5px;
  cursor: pointer;
  font-size: 0.85rem;
  transition: background 0.3s ease;
}

.quick-reply:hover {
  background: #0056b3;
}

/* Chat Input */
.chat-input-container {
  display: flex;
  padding: 10px;
  background: #f1f1f1;
  gap: 10px;
}

.chat-input {
  flex: 1;
  padding: 8px;
  border: 1px solid #ddd;
  border-radius: 5px;
  font-size: 0.9rem;
}

.chat-send-button {
  background: #007bff;
  color: white;
  border: none;
  padding: 8px 12px;
  border-radius: 5px;
  cursor: pointer;
}

.chat-send-button:hover {
  background: #0056b3;
}

/* Responsive Adjustments */
@media (max-width: 480px) {
  .chat-title {
    font-size: 1rem;
  }

  .chat-bubble {
    font-size: 0.85rem;
  }

  .chat-input {
    font-size: 0.85rem;
  }
}

/* ========== Responsive Design ========== */
@media (max-width: 768px) {
  .contact-container {
    flex-direction: column;
  }

  .banner-overlay h1 {
    font-size: 2rem;
  }

  .banner-overlay p {
    font-size: 1rem;
  }
}

.chat-bubble ul {
  margin: 10px 0;
  padding-left: 20px;
}

.chat-bubble ul li {
  margin: 5px 0;
}

.chat-bubble ul ul {
  margin-top: 5px;
  padding-left: 20px;
  font-size: 0.9rem;
}

.chatbot-container {
  max-width: 400px;

  background: #fff;
  border-radius: 8px;
  box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1);
  font-family: Arial, sans-serif;
}

.chatbot-header {
  background: linear-gradient(to right, #007bff, #0056b3);
  color: white;
  text-align: center;
  padding: 10px;
  font-size: 1.2rem;
  font-weight: bold;
}

.chatbot-window {
  padding: 15px;
  max-height: 300px;
  overflow-y: auto;
  background: #f9fafc;
}

.chatbot-message {
  display: flex;
  align-items: flex-start;
  margin-bottom: 10px;
}

.chatbot-serial {
  font-weight: bold;
  margin-right: 5px;
}

.chatbot-avatar {
  font-size: 1rem;
  margin: 0 8px;
  color: #007bff;
}

.chatbot-text {
  background: #e9ecef;
  border-radius: 10px;
  padding: 8px 12px;
}

.quick-reply {
  margin: 5px;
  background: #007bff;
  color: white;
  border-radius: 5px;
  cursor: pointer;
}

.chatbot-input-container {
  display: flex;
  padding: 10px;
}

.chatbot-input {
  flex: 1;
  border-radius: 5px;
  padding: 8px;
}

.chatbot-send-btn {
  margin-left: 5px;
  background: #007bff;
  color: white;
  border-radius: 5px;
}


.attractive-btn {
  background: linear-gradient(135deg, #ff7eb3, #ff758c);
  color: white;
  font-size: 1rem;
  font-weight: bold;
  border: none;
  border-radius: 50px;
  padding: 12px 25px;
  cursor: pointer;
  text-transform: uppercase;
  letter-spacing: 1px;
  transition: all 0.4s ease;
  box-shadow: 0 5px 15px rgba(255, 117, 140, 0.3);
}

.attractive-btn:hover {
  background: linear-gradient(135deg, #ff758c, #ff7eb3);
  transform: translateY(-3px);
  box-shadow: 0 8px 20px rgba(255, 117, 140, 0.4);
}

.attractive-btn:active {
  transform: translateY(1px);
  box-shadow: 0 4px 10px rgba(255, 117, 140, 0.2);
}