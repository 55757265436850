/* About Company Details Section */
.company-overview {
  background: linear-gradient(135deg, #4f8ef7, #1d4ed8);
  color: #ffffff;
  padding: 60px 40px;
  border-radius: 16px;
  margin: 30px auto;
  text-align: center;
  box-shadow: 0 10px 25px rgba(0, 0, 0, 0.2);
  font-family: 'Poppins', sans-serif;
  animation: fadeIn 1.2s ease-in-out;
  position: relative;
  overflow: hidden;
}

/* Animated Floating Background Effect */
.company-overview::before {
  content: '';
  position: absolute;
  top: -60%;
  left: -60%;
  width: 220%;
  height: 220%;
  background: radial-gradient(circle, rgba(255, 255, 255, 0.1) 30%, transparent 70%);
  animation: floatingBackground 20s infinite linear;
  z-index: 0;
  opacity: 0.3;
}

.company-overview h2 {
  font-size: 2.5rem;
  margin-bottom: 20px;
  text-transform: uppercase;
  font-weight: 700;
  letter-spacing: 1.2px;
  color: #ffffff;
  position: relative;
  z-index: 1;
  animation: slideInFromTop 1s ease-in-out;
}

.company-overview p {
  font-size: 1.1rem;
  margin-bottom: 20px;
  line-height: 1.6;
  font-weight: 400;
  position: relative;
  z-index: 1;
  animation: fadeInUp 1s ease-in-out;
}

/* Company Details List */
.company-overview ul {
  list-style: none;
  padding: 0;
  margin: 25px 0;
  position: relative;
  z-index: 1;
  animation: fadeInUp 1.2s ease-in-out;
}

.company-overview ul li {
  font-size: 1rem;
  margin: 10px 0;
  display: flex;
  align-items: center;
  font-weight: 500;
  gap: 12px;
}

.company-overview ul li::before {
  content: '🏢';
  color: #ffd700;
  font-size: 1.4rem;
  animation: popIn 0.8s ease-in-out;
}

.company-overview ul li strong {
  color: #ffde59;
  font-weight: 600;
}

/* Final Paragraph */
.company-overview p:last-child {
  font-size: 1rem;
  font-weight: 600;
  margin-top: 30px;
  animation: fadeIn 1.2s ease-in-out;
}

/* Hover Effects */
.company-overview:hover {
  transform: translateY(-5px);
  transition: all 0.4s ease-in-out;
  box-shadow: 0 15px 30px rgba(0, 0, 0, 0.25);
}

/* Keyframe Animations */
@keyframes fadeIn {
  from {
    opacity: 0;
  }
  to {
    opacity: 1;
  }
}

@keyframes fadeInUp {
  from {
    opacity: 0;
    transform: translateY(30px);
  }
  to {
    opacity: 1;
    transform: translateY(0);
  }
}

@keyframes slideInFromTop {
  from {
    transform: translateY(-30px);
    opacity: 0;
  }
  to {
    transform: translateY(0);
    opacity: 1;
  }
}

@keyframes popIn {
  0% {
    transform: scale(0.8);
    opacity: 0;
  }
  100% {
    transform: scale(1);
    opacity: 1;
  }
}

@keyframes floatingBackground {
  0% {
    transform: rotate(0deg);
  }
  100% {
    transform: rotate(360deg);
  }
}

/* Responsive Design */
@media (max-width: 768px) {
  .company-overview {
    padding: 30px 20px;
  }

  .company-overview h2 {
    font-size: 2rem;
  }

  .company-overview p {
    font-size: 1rem;
  }

  .company-overview ul li {
    font-size: 0.9rem;
  }
}
