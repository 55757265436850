.application-management-container {
  width: 90%;
  margin: 0 auto;
  font-family: Arial, sans-serif;
}

.application-management-container h1 {
  text-align: center;
  margin-bottom: 20px;
  font-size: 24px;
  color: #333;
}

/* Actions Section */
.actions {
  display: flex;
  justify-content: flex-start;
  align-items: center;
  margin-bottom: 20px;
  gap: 5px;
}

.actions input {
  padding: 6px 10px;
  font-size: 12px;
  border: 1px solid #ccc;
  border-radius: 5px;
  flex: none;
  transition: border-color 0.3s ease;
  width: 200px; /* Adjusted width */
}

.actions input:focus {
  border-color: #007bff;
  outline: none;
}

.actions .search-button {
  background-color: #007bff;
  color: white;
  border: none;
  padding: 6px 42px;
  border-radius: 5px;
  cursor: pointer;
  font-size: 12px;
  margin-right: auto;
}

.actions .search-button:hover {
  background-color: #0056b3;
}

.actions select {
  padding: 6px;
  font-size: 12px;
  border: 1px solid #ccc;
  border-radius: 5px;
  flex: none;
  width: auto;
}

/* Loading Spinner */
.loading-spinner {
  display: inline-block;
  width: 20px;
  height: 20px;
  border: 3px solid #ddd;
  border-top: 3px solid #007bff;
  border-radius: 50%;
  animation: spin 1s linear infinite;
}

@keyframes spin {
  to {
    transform: rotate(360deg);
  }
}

/* Table Styling */
table {
  width: 100%;
  border-collapse: collapse;
  margin-bottom: 20px;
}

table th,
table td {
  border: 1px solid #ddd;
  padding: 10px;
  text-align: left;
}

table th {
  background-color: #f8f9fa;
  font-size: 14px;
  color: #333;
}

table td {
  font-size: 13px;
  color: #555;
}

table tr:hover {
  background-color: #f1f1f1;
}

.action-button {
  margin-right: 5px;
  padding: 6px 10px;
  border-radius: 5px;
  cursor: pointer;
  border: none;
  font-size: 12px;
}

.action-button.accept {
  background-color: #28a745;
  color: white;
}

.action-button.accept:hover {
  background-color: #218838;
}

.action-button.decline {
  background-color: #dc3545;
  color: white;
}

.action-button.decline:hover {
  background-color: #c82333;
}

.action-button.download {
  background-color: #007bff;
  color: white;
}

.action-button.download:hover {
  background-color: #0056b3;
}

.action-button.view {
  background-color: #6c757d;
  color: white;
}

.action-button.view:hover {
  background-color: #5a6268;
}

/* Resume Viewer */
.resume-viewer {
  position: fixed;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  width: 80%;
  height: 70%;
  background-color: white;
  border-radius: 10px;
  box-shadow: 0 4px 8px rgba(0, 0, 0, 0.2);
  z-index: 1000;
  overflow: hidden;
  padding: 10px;
}

.resume-viewer iframe {
  border: none;
  width: 100%;
  height: calc(100% - 40px);
}

.resume-viewer .close-button {
  display: block;
  margin: 10px auto;
  padding: 8px 12px;
  background-color: #dc3545;
  color: white;
  border: none;
  border-radius: 5px;
  cursor: pointer;
  font-size: 14px;
  text-align: center;
}

.resume-viewer .close-button:hover {
  background-color: #c82333;
}

/* Pagination */
.pagination {
  display: flex;
  justify-content: center;
  align-items: center;
  gap: 10px;
}

.pagination button {
  background-color: #007bff;
  color: white;
  border: none;
  padding: 6px 12px;
  border-radius: 5px;
  cursor: pointer;
  font-size: 12px;
}

.pagination button:disabled {
  background-color: #ccc;
  cursor: not-allowed;
}

.pagination button:hover:not(:disabled) {
  background-color: #0056b3;
}

.pagination span {
  font-size: 14px;
  color: #333;
}

/* Decline Reason Section */
.decline-reason-container {
  margin-top: 20px;
  display: none;
}

.decline-reason-container.active {
  display: block;
}

.decline-reason-container textarea {
  width: 100%;
  height: 80px;
  padding: 8px;
  border: 1px solid #ccc;
  border-radius: 5px;
  font-size: 14px;
  resize: none;
  color: #333;
}

.actions select {
  margin: 0 10px;
  padding: 5px 10px;
  border-radius: 4px;
  border: 1px solid #ccc;
  font-size: 14px;
}

/* Responsive Design */
@media (max-width: 768px) {
  .actions {
    flex-direction: column;
    align-items: stretch;
  }

  .actions input {
    margin-bottom: 10px;
  }

  table th,
  table td {
    font-size: 12px;
    padding: 8px;
  }

  .action-button {
    font-size: 10px;
    padding: 5px 8px;
  }

  .pagination button {
    font-size: 12px;
    padding: 6px 10px;
  }
}

.action-button {
  position: relative;
  display: inline-flex;
  align-items: center;
  justify-content: center;
  gap: 5px;
  padding: 5px 10px;
  border-radius: 4px;
  cursor: pointer;
  transition: background-color 0.3s ease;
}

.action-button:disabled {
  cursor: not-allowed;
  opacity: 0.6;
}

.action-button .loader {
  border: 2px solid #f3f3f3; /* Light grey */
  border-top: 2px solid #3498db; /* Blue */
  border-radius: 50%;
  width: 14px;
  height: 14px;
  animation: spin 1s linear infinite;
  display: inline-block;
  margin: 0 auto;
}

@keyframes spin {
  0% {
    transform: rotate(0deg);
  }
  100% {
    transform: rotate(360deg);
  }
}

.actions {
  display: flex;
  flex-wrap: wrap;
  gap: 10px;
  align-items: center;
  margin-bottom: 20px;
}

.actions input,
.actions select {
  padding: 8px 12px;
  border: 1px solid #ccc;
  border-radius: 4px;
  font-size: 14px;
}

.actions .search-button {
  padding: 8px 16px;
  background-color: #007bff;
  color: white;
  border: none;
  border-radius: 4px;
  cursor: pointer;
}

.actions .search-button:hover {
  background-color: #0056b3;
}

/* Payment Details Form */
.payment-form-container {
  background: white;
  box-shadow: 0 4px 8px rgba(0, 0, 0, 0.2);
  border-radius: 8px;
  padding: 20px;
  max-width: 500px;
  margin: 20px auto;
}

.payment-form-container input {
  width: 100%;
  padding: 8px;
  margin-bottom: 10px;
  border: 1px solid #ddd;
  border-radius: 5px;
  font-size: 14px;
}

.payment-form-container label {
  font-size: 14px;
  margin-bottom: 4px;
  display: block;
}

.payment-form-container button {
  width: 48%;
  padding: 10px;
  font-size: 14px;
  border-radius: 5px;
  border: none;
  cursor: pointer;
}

.payment-form-container .submit-button {
  background-color: #28a745;
  color: white;
}

.payment-form-container .cancel-button {
  background-color: #dc3545;
  color: white;
}

.payment-form-container .cancel-button:hover {
  background-color: #c82333;
}

.action-button.payment {
  background-color: #ffc107;
  color: black;
}

.action-button.payment:hover {
  background-color: #e0a800;
}
.application-management-container {
  width: 90%;
  margin: 0 auto;
  font-family: Arial, sans-serif;
}

.application-management-container h1 {
  text-align: center;
  margin-bottom: 20px;
  font-size: 24px;
  color: #333;
}


.decline-reason-container .button-group {
  display: flex;
  justify-content: flex-end;
  gap: 10px;
}

.action-button.cancel {
  background-color: #6c757d;
  color: white;
}

.action-button.cancel:hover {
  background-color: #5a6268;
}




.modal {
  position: fixed;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  width: 80%;
  max-width: 500px;
  background-color: white;
  border-radius: 8px;
  box-shadow: 0 4px 8px rgba(0, 0, 0, 0.2);
  z-index: 1000;
  padding: 20px;
}

.modal-header {
  font-size: 18px;
  font-weight: bold;
  margin-bottom: 10px;
}

.modal-close-button {
  position: absolute;
  top: 10px;
  right: 10px;
  background: none;
  border: none;
  font-size: 20px;
  cursor: pointer;
}

.modal-backdrop {
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background: rgba(0, 0, 0, 0.5);
  z-index: 999;
}

/* Fade-in animation for modals */
@keyframes fadeIn {
  from {
    opacity: 0;
    transform: translateY(-10px);
  }
  to {
    opacity: 1;
    transform: translateY(0);
  }
}

.decline-reason-container.active {
  animation: fadeIn 0.3s ease-in-out;
}

/* Toast message animation */
.toast-success {
  animation: fadeIn 0.5s ease-out;
}


.pagination {
  display: flex;
  justify-content: center;
  align-items: center;
  gap: 8px;
}

.pagination button {
  padding: 8px 16px;
  border: none;
  border-radius: 4px;
  background-color: var(--primary-color);
  color: white;
  cursor: pointer;
  transition: background-color 0.3s ease;
}

.pagination button:hover:not(:disabled) {
  background-color: var(--primary-hover);
}

.pagination button:disabled {
  background-color: #ccc;
  color: #666;
  cursor: not-allowed;
}

.pagination .active {
  background-color: var(--success-color);
  color: white;
}

.empty-state {
  text-align: center;
  margin: 50px 0;
  color: #888;
  font-size: 16px;
}

.empty-state-icon {
  font-size: 48px;
  margin-bottom: 10px;
  color: #ccc;
}


/* Spinner for buttons */
.action-button.loading {
  position: relative;
  pointer-events: none;
}

.action-button.loading::after {
  content: "";
  position: absolute;
  top: 50%;
  left: 50%;
  width: 16px;
  height: 16px;
  border: 2px solid white;
  border-top-color: transparent;
  border-radius: 50%;
  animation: spinner 0.6s linear infinite;
  transform: translate(-50%, -50%);
}

@keyframes spinner {
  from {
    transform: translate(-50%, -50%) rotate(0deg);
  }
  to {
    transform: translate(-50%, -50%) rotate(360deg);
  }
}


/* Add hover effect for table rows */
table tr:hover {
  background-color: #f5f5f5;
  cursor: pointer; /* Indicates interactivity */
}

/* Add focus outline for accessibility */
.action-button:focus {
  outline: 2px solid var(--primary-color);
  outline-offset: 2px;
}

/* Input field focus */
.actions input:focus,
.actions select:focus {
  border-color: var(--primary-color);
  box-shadow: 0 0 5px var(--primary-color);
}
