.policy-container {
    max-width: 800px;
    margin: 0 auto;
    padding: 20px;
    font-family: Arial, sans-serif;
    line-height: 1.6;
    color: #333;
  }
  
  .policy-container h1 {
    text-align: center;
    margin-bottom: 20px;
    font-size: 2.2em;
    color: #000;
  }
  
  .policy-container h2 {
    margin-top: 20px;
    font-size: 1.5em;
    border-bottom: 2px solid #ccc;
    padding-bottom: 5px;
  }
  
  .policy-container p {
    margin: 10px 0;
  }
  
  .policy-container ul {
    margin: 10px 0 20px 20px;
  }
  
  .policy-container li {
    margin-bottom: 10px;
  }
  
  .policy-container ol {
    margin: 10px 0 20px 20px;
  }
  
  .policy-container a {
    color: #0078d4;
    text-decoration: none;
  }
  
  .policy-container a:hover {
    text-decoration: underline;
  }
  
  .policy-container strong {
    font-weight: bold;
  }
  