/* TeamSection.css */
.team {
    padding: 60px 20px;
    background: #f9fafc;
    text-align: center;
  }
  
  .section-title {
    font-size: 2.5rem;
    color: #007bff;
    margin-bottom: 20px;
    font-weight: bold;
  }
  
  .section-subtitle {
    font-size: 1.1rem;
    color: #666;
    margin-bottom: 40px;
  }
  
  .team-members {
    display: grid;
    grid-template-columns: repeat(auto-fit, minmax(250px, 1fr));
    gap: 20px;
    justify-items: center;
  }
  
  .team-member {
    background: #ffffff;
    padding: 20px;
    border-radius: 10px;
    box-shadow: 0 4px 10px rgba(0, 0, 0, 0.1);
    text-align: center;
    transition: transform 0.3s ease, box-shadow 0.3s ease;
    max-width: 300px;
  }
  
  .team-member:hover {
    transform: translateY(-5px);
    box-shadow: 0 8px 20px rgba(0, 0, 0, 0.2);
  }
  
  .team-image-container {
    width: 120px;
    height: 120px;
    margin: 0 auto 15px;
    overflow: hidden;
    border-radius: 50%;
    border: 3px solid #0073e6;
  }
  
  .team-image {
    width: 100%;
    height: 100%;
    object-fit: cover;
  }
  
  .team-name {
    font-size: 1.2rem;
    font-weight: bold;
    color: #333;
    margin-bottom: 5px;
  }
  
  .team-role {
    font-size: 1rem;
    color: #0073e6;
    margin-bottom: 10px;
  }
  
  .team-qualification {
    font-size: 0.9rem;
    color: #555;
    line-height: 1.5;
  }
  
  /* Responsive Design */
  @media (max-width: 768px) {
    .team-members {
      grid-template-columns: 1fr 1fr;
    }
  
    .team-name {
      font-size: 1rem;
    }
  
    .team-role {
      font-size: 0.9rem;
    }
  
    .team-qualification {
      font-size: 0.8rem;
    }
  }
  
  @media (max-width: 480px) {
    .team-members {
      grid-template-columns: 1fr;
    }
  
    .team-member {
      max-width: 90%;
    }
  
    .team-name {
      font-size: 1rem;
    }
  
    .team-role {
      font-size: 0.8rem;
    }
  
    .team-qualification {
      font-size: 0.75rem;
    }
  }
  