.privacy-policy {
  max-width: 900px;
  margin: 0 auto;
  padding: 20px;
  font-family: Arial, sans-serif;
  line-height: 1.8;
  color: #333;
}

.privacy-policy h1 {
  text-align: center;
  margin-bottom: 20px;
  font-size: 2.5em;
  color: #000;
}

.privacy-policy h2 {
  margin-top: 20px;
  font-size: 1.8em;
  border-bottom: 2px solid #ddd;
  padding-bottom: 5px;
}

.privacy-policy p {
  margin: 10px 0;
}

.privacy-policy ul {
  margin: 10px 0 20px 20px;
}

.privacy-policy li {
  margin-bottom: 10px;
}

.privacy-policy a {
  color: #0078d4;
  text-decoration: none;
}

.privacy-policy a:hover {
  text-decoration: underline;
}

.privacy-policy strong {
  font-weight: bold;
}
