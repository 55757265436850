/* General Styles */
.interview-status-form-container {
    padding: 20px;
    max-width: 500px;
    margin: 20px auto;
    background: #f9f9f9;
    border-radius: 8px;
    box-shadow: 0 2px 4px rgba(0, 0, 0, 0.1);
    font-family: Arial, sans-serif;
  }
  
  h2 {
    text-align: center;
    color: #333;
    margin-bottom: 20px;
  }
  
  .form-group {
    margin-bottom: 15px;
  }
  
  label {
    display: block;
    margin-bottom: 5px;
    font-weight: bold;
    color: #555;
  }
  
  textarea {
    width: 100%;
    padding: 10px;
    border: 1px solid #ccc;
    border-radius: 4px;
    resize: none;
    font-size: 14px;
  }
  
  /* Button Styles */
  .button-group {
    display: flex;
    justify-content: space-between;
    margin-top: 20px;
  }
  
  .submit-button,
  .cancel-button {
    padding: 10px 20px;
    border: none;
    border-radius: 4px;
    font-size: 14px;
    cursor: pointer;
  }
  
  .submit-button {
    background-color: #4caf50;
    color: white;
    transition: background-color 0.3s ease;
  }
  
  .submit-button:hover {
    background-color: #45a049;
  }
  
  .cancel-button {
    background-color: #f44336;
    color: white;
    transition: background-color 0.3s ease;
  }
  
  .cancel-button:hover {
    background-color: #d32f2f;
  }
  
  /* Media Queries for Responsiveness */
  @media (max-width: 768px) {
    .interview-status-form-container {
      max-width: 90%;
      padding: 15px;
    }
  
    h2 {
      font-size: 18px;
    }
  
    textarea {
      font-size: 12px;
    }
  
    .submit-button,
    .cancel-button {
      padding: 8px 16px;
      font-size: 12px;
    }
  }
  
  @media (max-width: 480px) {
    .interview-status-form-container {
      padding: 10px;
    }
  
    h2 {
      font-size: 16px;
    }
  
    textarea {
      font-size: 10px;
    }
  
    .submit-button,
    .cancel-button {
      font-size: 10px;
      padding: 6px 12px;
    }
  }

  .interview-status-form-container select {
    width: 100%;
    padding: 10px;
    margin-top: 5px;
    margin-bottom: 15px;
    border: 1px solid #ccc;
    border-radius: 4px;
    font-size: 14px;
  }
  
  .interview-status-form-container textarea {
    resize: none;
  }
  
  