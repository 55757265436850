/* === Global Footer Styles === */
.footer {
  background: linear-gradient(135deg, #1f2937, #2563eb);
  color: #ffffff;
  font-family: 'Arial', sans-serif;
  padding: 50px 0;
}

.footer-container {
  display: grid;
  grid-template-columns: repeat(auto-fit, minmax(250px, 1fr));
  gap: 30px;
  padding: 0 50px;
}

/* === Section Titles === */
.footer-section h3 {
  font-size: 1.2em;
  margin-bottom: 15px;
  border-bottom: 2px solid #4ade80;
  display: inline-block;
  padding-bottom: 5px;
}

/* === About Section === */
.footer-section.about p {
  font-size: 0.95em;
  margin-bottom: 10px;
}

.company-details {
  list-style-type: none;
  margin-top: 10px;
  padding-left: 0;
}

.company-details li {
  margin: 5px 0;
  font-size: 0.9em;
}

/* === ISO Certification Section === */
.footer-section.certification p {
  font-size: 0.95em;
  margin-bottom: 8px;
}

.footer-section.certification ul {
  margin: 10px 0;
  padding-left: 0;
  list-style: none;
}

.footer-section.certification ul li {
  font-size: 0.9em;
}

/* === Services Section === */
.footer-section.services .service-filter {
  margin-bottom: 10px;
  padding: 8px;
  width: 100%;
  border: 1px solid #4ade80;
  border-radius: 5px;
}

.footer-section.services ul {
  list-style-type: none;
  padding: 0;
}

.footer-section.services ul li {
  margin: 8px 0;
  font-size: 0.9em;
}

/* === Contact Section === */
.footer-section.contact p {
  margin: 8px 0;
  font-size: 0.9em;
  display: flex;
  align-items: center;
}

.footer-section.contact p .fa-icon {
  margin-right: 10px;
}

/* === Newsletter Subscription Section === */
.footer-section.newsletter input[type="email"] {
  width: 100%;
  margin-top: 10px;
  padding: 10px;
  border: 1px solid #4ade80;
  border-radius: 5px;
  font-size: 0.9em;
}

.footer-section.newsletter button {
  margin-top: 10px;
  background-color: #4ade80;
  color: #ffffff;
  border: none;
  padding: 8px 15px;
  font-size: 0.9em;
  border-radius: 5px;
  cursor: pointer;
  transition: background-color 0.3s ease-in-out;
}

.footer-section.newsletter button:hover {
  background-color: #38a169;
}

/* === Social Icons Section === */
.footer-section.follow-us .social-icons {
  margin-top: 15px;
}

.footer-section.follow-us .social-icons a {
  font-size: 1.5em;
  margin-right: 10px;
  color: #ffffff;
  transition: transform 0.3s ease-in-out;
}

.footer-section.follow-us .social-icons a:hover {
  transform: scale(1.2);
  color: #4ade80;
}

/* === Legal Links === */
.footer-legal {
  text-align: center;
  margin-top: 30px;
  font-size: 0.9em;
}

.footer-legal a {
  color: #dbeafe;
  text-decoration: none;
  margin: 0 8px;
}

.footer-legal a:hover {
  text-decoration: underline;
  color: #4ade80;
}

/* === Footer Bottom === */
.footer-bottom {
  text-align: center;
  margin-top: 30px;
  padding-top: 15px;
  border-top: 1px solid #4b5563;
  font-size: 0.85em;
  color: #dbeafe;
}

/* === Cookies Consent Banner === */
.cookies-banner {
  position: fixed;
  bottom: 0;
  left: 0;
  width: 100%;
  background: #1a202c;
  color: #ffffff;
  text-align: center;
  padding: 10px 20px;
  font-size: 0.9em;
  display: flex;
  justify-content: space-between;
  align-items: center;
  z-index: 9999;
}

.cookies-banner p {
  margin: 0;
  font-size: 0.9em;
}

.cookies-banner a {
  color: #4ade80;
  text-decoration: underline;
}

.cookies-banner button {
  background: #4ade80;
  border: none;
  color: #ffffff;
  padding: 8px 15px;
  font-size: 0.9em;
  border-radius: 5px;
  cursor: pointer;
  margin-left: 10px;
}

.cookies-banner button:hover {
  background: #38a169;
}

/* === Responsive Design === */
@media (max-width: 768px) {
  .footer-container {
    grid-template-columns: 1fr;
    text-align: center;
  }

  .footer-section h3 {
    text-align: center;
  }

  .footer-section.contact p {
    justify-content: center;
  }

  .footer-section.follow-us .social-icons {
    justify-content: center;
  }

  .cookies-banner {
    flex-direction: column;
    align-items: center;
    gap: 10px;
  }
}
