/* HeroSection.css */
.hero-section {
    position: relative;
    width: 100%;
    height: 500px;
    overflow: hidden;
  }
  
  .hero-image {
    width: 100%;
    height: 100%;
    object-fit: cover;
  }
  
 
  
  .hero-overlay h1 {
    font-size: 2.5rem;
    margin-bottom: 10px;
  }
  
  .hero-overlay p {
    font-size: 1.2rem;
    margin-bottom: 20px;
  }
  
  .hero-button {
    background: #007bff;
    color: white;
    border: none;
    padding: 10px 20px;
    border-radius: 5px;
    cursor: pointer;
    transition: background 0.3s ease;
  }
  
  .hero-button:hover {
    background: #0056b3;
  }
  
  .hero-info {
    background: #f9f9f9;
    padding: 40px 20px;
    text-align: center;
    margin-top: 20px;
  }
  
  .hero-stats {
    display: flex;
    justify-content: center;
    gap: 30px;
    flex-wrap: wrap;
  }
  
  .stat-item {
    background: #fff;
    padding: 20px;
    border-radius: 10px;
    box-shadow: 0 4px 10px rgba(0, 0, 0, 0.1);
    text-align: center;
    transition: transform 0.3s ease;
  }
  
  .stat-item:hover {
    transform: translateY(-5px);
  }
  
  .stat-item h3 {
    font-size: 2rem;
    color: #007bff;
    margin-bottom: 5px;
  }
  
  .stat-item p {
    font-size: 1rem;
    color: #555;
  }
  
  /* Responsive Design */
  @media (max-width: 768px) {
    .hero-overlay h1 {
      font-size: 2rem;
    }
  
    .hero-overlay p {
      font-size: 1rem;
    }
  
    .hero-button {
      padding: 8px 16px;
    }
  
    .hero-stats {
      flex-direction: column;
      gap: 15px;
    }
  }
  
  @media (max-width: 480px) {
    .hero-overlay h1 {
      font-size: 1.8rem;
    }
  
    .hero-overlay p {
      font-size: 0.9rem;
    }
  
    .hero-button {
      padding: 6px 12px;
    }
  }