/* Root Variables */
:root {
  --primary-color: #1d3557;
  --secondary-color: #457b9d;
  --accent-color: #f4a261;
  --accent-hover: #e76f51;
  --text-color: #333;
  --bg-light: #f9f9f9;
}

/* General Styles */
body, html {
  margin: 0;
  padding: 0;
  font-family: "Roboto", sans-serif;
  background-color: var(--bg-light);
}

/* Banner Section */
.banner {
  position: relative;
  width: 100%;
  height: 300px;
  display: flex;
  justify-content: center;
  align-items: center;
  overflow: hidden;
}

.banner img {
  width: 100%;
  height: 100%;
  object-fit: cover;
}

.banner-overlay {
  position: absolute;
  inset: 0;
  background: rgba(0, 0, 0, 0.5);
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  color: white;
  text-align: center;
  padding: 1rem;
  width: 100%;
}

.banner-overlay h1 {
  font-size: clamp(1.8rem, 5vw, 2.5rem);
  margin-bottom: 10px;
  text-align: center;
  width: 90%;
}

.banner-overlay p {
  font-size: clamp(1rem, 2.5vw, 1.2rem);
  text-align: center;
  width: 90%;
}

/* View Openings Button */
.view-openings-btn {
  background-color: var(--accent-color);
  color: white;
  padding: 0.75rem 1.5rem;
  font-size: 1rem;
  font-weight: bold;
  border: none;
  border-radius: 6px;
  cursor: pointer;
  text-align: center;
  transition: background-color 0.3s ease;
  display: flex;
  justify-content: center;
  align-items: center;
  max-width: 250px; /* Prevents stretching */
  width: 90%; /* Responsive width */
  margin: 20px auto; /* Centers the button */
}

.view-openings-btn:hover {
  background-color: var(--accent-hover);
}

/* Content Layout */
.content {
  display: flex;
  flex-wrap: wrap;
  gap: 20px;
  padding: 1rem;
  justify-content: center;
}

/* Job Listings */
.job-list {
  flex: 3;
  min-width: 250px;
}

/* Job Card */
.job-card {
  border: 1px solid #ddd;
  padding: 1rem;
  margin-bottom: 1.5rem;
  border-radius: 8px;
  background-color: var(--bg-light);
  box-shadow: 0 2px 4px rgba(0, 0, 0, 0.1);
}

.job-card h3 {
  margin-bottom: 0.5rem;
  color: var(--primary-color);
  font-size: 1.2rem;
}

.job-card p {
  margin: 0.5rem 0;
  font-size: 1rem;
}

/* Buttons */
.btn {
  display: inline-block;
  padding: 0.75rem 1.5rem;
  font-size: 1rem;
  font-weight: bold;
  border: none;
  border-radius: 6px;
  cursor: pointer;
  text-align: center;
  transition: background-color 0.3s ease;
}

.apply-button {
  background-color: var(--primary-color);
  color: white;
}

.apply-button:hover {
  background-color: var(--secondary-color);
}

/* Filter Panel */
.filter-panel {
  flex: 1;
  min-width: 250px;
  background-color: white;
  padding: 1rem;
  border-radius: 8px;
  box-shadow: 0 2px 4px rgba(0, 0, 0, 0.1);
}

/* Inputs & Buttons */
button, input, select {
  margin: 10px;
  font-family: inherit;
  font-size: inherit;
  line-height: inherit;
}

/* 📌 Responsive Design */
@media (max-width: 1024px) {
  .content {
      flex-direction: column;
      align-items: center;
  }
  
  .filter-panel {
      width: 100%;
  }
}

@media (max-width: 768px) {
  .banner {
      height: 250px;
  }
  
  .banner-overlay h1 {
      font-size: 1.8rem;
  }

  .banner-overlay p {
      font-size: 1rem;
  }

  .content {
      flex-direction: column;
      padding: 0.5rem;
  }

  .job-card {
      padding: 0.8rem;
  }

  .btn, .view-openings-btn {
      width: 100%; /* Makes buttons full width on small screens */
  }
}

@media (max-width: 480px) {
  .banner {
      height: 200px;
  }
  
  .banner-overlay {
      padding: 0.5rem;
  }
  
  .banner-overlay h1 {
      font-size: 1.5rem;
  }

  .banner-overlay p {
      font-size: 0.9rem;
  }

  .job-card {
      padding: 0.7rem;
  }

  .btn, .view-openings-btn {
      font-size: 0.9rem;
      padding: 0.5rem 1rem;
      width: 100%;
  }
}
