.container {
  font-family: Arial, sans-serif;
  max-width: 900px;
  margin: auto;
  padding: 20px;
}

h1 {
  text-align: center;
  margin-bottom: 20px;
}

.search-box {
  text-align: center;
  margin-bottom: 20px;
}

.search-input {
  display: flex;
  justify-content: center;
  gap: 10px;
}

.search-input input {
  width: 300px;
  padding: 10px;
  border: 1px solid #ccc;
  border-radius: 5px;
}

.search-input button {
  padding: 10px 20px;
  background-color: #0078d4;
  color: white;
  border: none;
  border-radius: 5px;
  cursor: pointer;
}

.error {
  color: red;
  text-align: center;
  margin: 10px 0;
}

.chart-section {
  margin-top: 30px;
  text-align: center;
}

.chart-container {
  width: 400px;
  height: 300px;
  margin: 0 auto;
  border: 1px solid #ddd;
  border-radius: 8px;
  padding: 10px;
  background-color: #fafafa;
}

@media (max-width: 600px) {
  .chart-container {
    width: 100%;
    height: 250px;
  }
}

/* Resume Viewer */
.resume-viewer {
  position: fixed;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  width: 80%;
  height: 70%;
  background-color: white;
  border-radius: 10px;
  box-shadow: 0 4px 8px rgba(0, 0, 0, 0.2);
  z-index: 1000;
  overflow: hidden;
  padding: 10px;
}

.resume-viewer iframe {
  border: none;
  width: 100%;
  height: calc(100% - 40px);
}

.resume-viewer .close-button {
  display: block;
  margin: 10px auto;
  padding: 8px 12px;
  background-color: #dc3545;
  color: white;
  border: none;
  border-radius: 5px;
  cursor: pointer;
  font-size: 14px;
  text-align: center;
}

.resume-viewer .close-button:hover {
  background-color: #c82333;
}
