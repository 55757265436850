/* ServicesSection.css */

  
  .section-title {
    font-size: 2.5rem;
    color: #007bff;
    margin-bottom: 20px;
    font-weight: bold;
  }
  
  .section-subtitle {
    font-size: 1.1rem;
    color: #666;
    margin-bottom: 40px;
  }
  
  .services-list {
    display: grid;
    grid-template-columns: repeat(auto-fit, minmax(250px, 1fr));
    gap: 20px;
    justify-items: center;
  }
  
  .service-item {
    background: #ffffff;
    padding: 20px;
    border-radius: 10px;
    box-shadow: 0 4px 10px rgba(0, 0, 0, 0.1);
    text-align: center;
    transition: transform 0.3s ease, box-shadow 0.3s ease;
    max-width: 300px;
  }
  
  .service-item:hover {
    transform: translateY(-5px);
    box-shadow: 0 8px 20px rgba(0, 0, 0, 0.2);
  }
  
  
  .service-item:hover .service-icon {
    color: #ff6b6b;
  }
  
  .service-title {
    font-size: 1.2rem;
    font-weight: bold;
    margin-bottom: 10px;
  }
  
  .service-content {
    font-size: 1rem;
    color: #555;
    line-height: 1.5;
  }
  
  /* Responsive Design */
  @media (max-width: 768px) {
    .services-list {
      grid-template-columns: 1fr 1fr;
    }
  }
  
  @media (max-width: 480px) {
    .services-list {
      grid-template-columns: 1fr;
    }
  }
  