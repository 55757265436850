/* General Styles */
body {
  margin: 0;
  font-family: 'Roboto', sans-serif;

  color: #333;
}

/* Centered Layout */
.center-container {
  display: flex;
  justify-content: center;
  align-items: center;
  min-height: 100vh;
  padding: 20px;
  box-sizing: border-box;
}

/* Form Container */
.form-container {
  background: #fff;
  border-radius: 15px;
  box-shadow: 0 10px 30px rgba(0, 0, 0, 0.2);
  width: 100%;
  max-width: 400px;
  padding: 25px;
  text-align: center;
  overflow-y: auto;
  animation: fadeIn 1s ease-in-out;
}

/* Back Button */
.back-btn {
  /*position: absolute;
  top: 20px;*/
  left: 20px;
  padding: 10px 20px;
  font-size: 14px;
  font-weight: bold;
  color: #fff;
  background: #42a5f5;
  border: none;
  border-radius: 50px;
  cursor: pointer;
  box-shadow: 0 5px 15px rgba(0, 0, 0, 0.3);
  transition: all 0.3s ease;
}

.back-btn:hover {
  background: #1976d2;
}

/* Form Header */
.form-header {
  margin-bottom: 20px;
}

.form-header img {
  width: 80px;
  height: auto;
  margin-bottom: 10px;
}

.form-header h1 {
  font-size: 24px;
  color: #1976d2;
  font-weight: bold;
}

/* Input Group */
.input-group {
  position: relative;
  margin-bottom: 20px;
}

.input-group .icon {
  position: absolute;
  top: 50%;
  left: 15px;
  transform: translateY(-50%);
  color: #42a5f5;
}

.input-group input {
  width: 100%;
  padding: 12px 15px 12px 40px;
  font-size: 16px;
  border: 1px solid #ccc;
  border-radius: 50px;
  box-sizing: border-box;
  transition: all 0.3s ease;
}

.input-group input:focus {
  border-color: #42a5f5;
  outline: none;
  box-shadow: 0 0 5px rgba(66, 165, 245, 0.5);
}

/* Submit Button */
.submit-btn {
  width: 100%;
  padding: 12px 15px;
  font-size: 16px;
  font-weight: bold;
  color: white;
  background: #1976d2;
  border: none;
  border-radius: 50px;
  cursor: pointer;
  box-shadow: 0 5px 15px rgba(25, 118, 210, 0.3);
  transition: all 0.3s ease;
}

.submit-btn:hover {
  background: #155fa0;
  box-shadow: 0 8px 20px rgba(25, 118, 210, 0.5);
}

/* Google Login Button */
.google-login-btn {
  display: flex;
  align-items: center;
  justify-content: center;
  width: 100%;
  padding: 12px 15px;
  margin-top: 15px;
  font-size: 16px;
  font-weight: bold;
  color: #4285f4;
  background: #fff;
  border: 2px solid #4285f4;
  border-radius: 50px;
  cursor: pointer;
  box-shadow: 0 5px 15px rgba(0, 0, 0, 0.1);
  transition: all 0.3s ease;
}

.google-login-btn img {
  width: 20px;
  height: 20px;
  margin-right: 10px;
}

.google-login-btn:hover {
  background: #4285f4;
  color: #fff;
  box-shadow: 0 8px 20px rgba(66, 133, 244, 0.5);
}

/* Forgot Password Link */
.forgot-password {
  display: block;
  text-align: right;
  margin-top: -10px;
  margin-bottom: 15px;
  font-size: 14px;
  color: #42a5f5;
  font-weight: bold;
  cursor: pointer;
  transition: color 0.3s ease;
}

.forgot-password:hover {
  color: #1976d2;
}

/* Signup Link */
.signup-link {
  display: block;
  margin-top: 10px;
  font-size: 14px;
  color: #42a5f5;
  font-weight: bold;
  cursor: pointer;
  transition: color 0.3s ease;
}

.signup-link:hover {
  color: #1976d2;
}

/* CAPTCHA */
.captcha-box {
  display: flex;
  align-items: center;
  justify-content: space-between;
  margin-bottom: 15px;
  padding: 10px;
  border: 1px solid #ccc;
  border-radius: 50px;
  background: #f9f9f9;
}

.captcha-text {
  font-size: 16px;
  font-weight: bold;
  color: #1976d2;
}

.refresh-captcha {
  background: #1976d2;
  color: #fff;
  padding: 5px 10px;
  font-size: 14px;
  font-weight: bold;
  border: none;
  border-radius: 50px;
  cursor: pointer;
  transition: background 0.3s ease;
}

.refresh-captcha:hover {
  background: #155fa0;
}

/* Responsive Design */
@media (max-width: 768px) {
  .form-container {
    width: 90%;
  }

  .back-btn {
    font-size: 12px;
    padding: 8px 12px;
  }

  .submit-btn,
  .google-login-btn {
    font-size: 14px;
  }
}

@keyframes fadeIn {
  from {
    opacity: 0;
    transform: scale(0.9);
  }
  to {
    opacity: 1;
    transform: scale(1);
  }
}

/* Form Container with Scroll */
.form-container {
  background: #fff;
  border-radius: 15px;
  box-shadow: 0 5px 15px rgba(0, 0, 0, 0.2);
  width: 90%;
  max-width: 400px;
  max-height: 80vh;
  padding: 20px;
  text-align: center;
  overflow-y: auto;
  scroll-behavior: smooth;
}

/* Scrollbar Styling */
.form-container::-webkit-scrollbar {
  width: 6px;
}

.form-container::-webkit-scrollbar-thumb {
  background: #1976d2;
  border-radius: 10px;
}

.form-container::-webkit-scrollbar-track {
  background: #f1f1f1;
}

/* Improved Back Button */
.back-btn {
  position: absolute;
  top: 15px;
  left: 15px;
  padding: 10px 15px;
  font-size: 14px;
  font-weight: bold;
  color: #fff;
  background: #1976d2;
  border: none;
  border-radius: 50px;
  cursor: pointer;
  box-shadow: 0 4px 10px rgba(0, 0, 0, 0.3);
  transition: all 0.3s ease-in-out;
  display: flex;
  align-items: center;
  gap: 5px;
}

.back-btn:hover {
  background: #155fa0;
  transform: scale(1.05);
  box-shadow: 0 6px 15px rgba(25, 118, 210, 0.5);
}

.back-btn svg {
  font-size: 16px;
}

/* Ensure form container has relative positioning for absolute back button */
.form-container {
  position: relative;
  padding-top: 50px; /* Add space for back button */
}




