/* ✅ General Styles */
body {
  margin: 0;
  font-family: "Poppins", Arial, sans-serif;
  background-color: #f9fafc;
  color: #333;
  line-height: 1.6;
}

h1 {
  text-align: center;
  margin-bottom: 30px;
  font-size: 32px;
  color: #1e293b;
  font-weight: 700;
}

/* ✅ Container */
.blogs-container {
  margin: 0 auto;
  max-width: 1200px;
  padding: 20px;
}

/* ✅ Layout */
.layout {
  display: flex;
  gap: 24px;
  margin-top: 20px;
}

/* ✅ Sidebar */
.sidebar {
  width: 30%;
  background: linear-gradient(135deg, #f0f4f8, #e2e8f0);
  padding: 20px;
  border-radius: 12px;
  box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1);
}

.sidebar h2 {
  font-size: 20px;
  margin-bottom: 15px;
  color: #1e40af;
}

.sidebar ul {
  list-style: none;
  padding: 0;
}

.sidebar li {
  margin-bottom: 10px;
}

.sidebar li a {
  text-decoration: none;
  color: #475569;
  font-size: 16px;
  transition: color 0.3s;
}

.sidebar li a:hover {
  color: #2563eb;
  font-weight: bold;
}

/* ✅ Main Content */
.main-content {
  flex: 1;
  padding: 20px;
  background: #ffffff;
  border-radius: 12px;
  box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1);
}

/* ✅ Search Bar */
.search-bar {
  display: flex;
  align-items: center;
  width: 100%;
  max-width: 300px;
  border-radius: 8px;
  overflow: hidden;
  box-shadow: 0 2px 4px rgba(0, 0, 0, 0.1);
}

.search-bar input {
  flex: 1;
  padding: 8px;
  font-size: 14px;
  border: none;
  outline: none;
}

.search-bar button {
  padding: 8px 12px;
  background-color: #2563eb;
  color: white;
  font-size: 14px;
  border: none;
  cursor: pointer;
  transition: background-color 0.3s;
}

.search-bar button:hover {
  background-color: #1d4ed8;
}

/* ✅ Blogs Grid */
.blogs-grid {
  display: grid;
  grid-template-columns: repeat(auto-fill, minmax(280px, 1fr));
  gap: 24px;
  margin-top: 10px;
}

.blog-card {
  background: #ffffff;
  border-radius: 8px;
  box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1);
  overflow: hidden;
  transition: transform 0.3s ease-in-out;
}

.blog-card:hover {
  transform: translateY(-5px);
}

.blog-card img {
  width: 100%;
  height: 180px;
  object-fit: cover;
}

.blog-card h3 {
  font-size: 18px;
  margin: 10px 15px;
  color: #1e293b;
}

.blog-card p {
  font-size: 14px;
  margin: 0 15px 10px;
  color: #64748b;
}

/* ✅ Load More Button */
.load-more-btn {
  display: block;
  margin: 30px auto;
  padding: 10px 18px;
  font-size: 14px;
  background: #2563eb;
  color: white;
  border: none;
  border-radius: 8px;
  cursor: pointer;
  font-weight: 600;
  transition: background-color 0.3s;
}

.load-more-btn:hover {
  background-color: #1d4ed8;
}

/* ✅ Blog Details */
.blog-detail {
  margin-top: 20px;
  position: relative;
}

.blog-detail .back-btn {
  display: inline-block;
  margin-bottom: 20px;
  background-color: transparent;
  border: none;
  color: #007bff;
  font-size: 16px;
  cursor: pointer;
  position: absolute;
  top: 0;
  left: 0;
  padding: 10px 20px;
  transition: color 0.3s ease;
}

.blog-detail .back-btn:hover {
  color: #1e293b;
}

/* ✅ Comments Section */
.comment-section {
  margin-top: 30px;
  padding: 20px;
  background: #f8fafc;
  border-radius: 8px;
  border: 1px solid #e2e8f0;
}

/* ✅ Responsive Design */
@media (max-width: 1024px) {
  .layout {
    flex-direction: column;
  }

  .sidebar {
    width: 100%;
  }
}

@media (max-width: 768px) {
  .blogs-container {
    padding: 10px;
  }

  h1 {
    font-size: 26px;
  }
}

@media (max-width: 480px) {
  h1 {
    font-size: 22px;
  }

  .search-bar {
    flex-direction: column;
  }

  .search-bar input {
    border-radius: 5px;
    margin-bottom: 5px;
  }
}

.blog-loader {
  display: grid;
  grid-template-columns: repeat(auto-fill, minmax(300px, 1fr));
  gap: 20px;
  padding: 20px;
  justify-content: center;
}

.blog-placeholder {
  width: 100%;
  height: 250px;
  display: flex;
  align-items: center;
  justify-content: center;
  background: #f5f5f5;
  border-radius: 10px;
}

.large-spinner {
  font-size: 40px;
  color: #007bff;
  animation: spin 1s linear infinite;
}

@keyframes spin {
  0% { transform: rotate(0deg); }
  100% { transform: rotate(360deg); }
}
/* ✅ Blog Detail Section */
/* ✅ Blog Detail Section */
.blog-detail {
  position: relative;
  margin-top: 20px;
  padding-top: 50px;  /* Ensure there's space for the back button */
}

/* ✅ Back Button */
.back-btn {
  display: inline-block;
  margin-bottom: 20px;
  background-color: transparent;
  border: none;
  color: #007bff;
  font-size: 16px;
  cursor: pointer;
  padding: 10px 20px;
  position: absolute;
  top: 20px; /* Positioned at the top of the blog card */
  left: 20px;
  transition: color 0.3s ease;
  z-index: 10;
}

.back-btn:hover {
  color: #1e293b;
}

/* ✅ Main Content */
.main-content {
  position: relative;
  flex: 1;
  padding: 20px;
  background: #fff;
  border-radius: 8px;
  box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1);
}

.blog-card {
  background: #ffffff;
  border-radius: 8px;
  box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1);
  overflow: hidden;
  transition: transform 0.3s ease-in-out;
}

.blog-card:hover {
  transform: translateY(-5px);
}

.blog-card img {
  width: 100%;
  height: 180px;
  object-fit: cover;
}

/* ✅ Additional Layout and Styling */
.blogs-grid {
  display: grid;
  grid-template-columns: repeat(auto-fill, minmax(280px, 1fr));
  gap: 24px;
}

/* ✅ Blog Detail Section */
.blog-detail {
  position: relative;
  margin-top: 20px;
  padding-top: 50px;
}

/* ✅ Back Button */
.back-btn {
  display: inline-block;
  margin-bottom: 20px;
  background-color: transparent;
  border: none;
  color: #007bff;
  font-size: 16px;
  cursor: pointer;
  padding: 10px 20px;
  position: absolute;
  top: 20px; /* Positioned at the top of the blog card */
  left: 20px;
  transition: color 0.3s ease;
  z-index: 10;
}

.back-btn:hover {
  color: #1e293b;
}

/* ✅ Simple Loader Text */
.simple-loader {
  font-size: 20px;
  color: #007bff;
  text-align: center;
  margin-top: 20px;
  font-weight: 500;
}

/* ✅ Placeholder Loader */
.blog-placeholder {
  width: 100%;
  height: 250px;
  background: #f0f0f0;
  border-radius: 8px;
  display: flex;
  justify-content: center;
  align-items: center;
}

/* ✅ Custom Image Loader */
.blog-card img {
  width: 100%;
  height: 180px;
  object-fit: cover;
}

/* ✅ Loader Spinner */
.large-spinner {
  font-size: 40px;
  color: #007bff;
  animation: spin 1s linear infinite;
}

@keyframes spin {
  0% { transform: rotate(0deg); }
  100% { transform: rotate(360deg); }
}
/* ✅ Blog Detail Section */
.blog-detail {
  position: relative;
  margin-top: 20px;
  padding-top: 50px;
}

/* ✅ Back Button */
.back-btn {
  display: inline-block;
  margin-bottom: 20px;
  background-color: transparent;
  border: none;
  color: #007bff;
  font-size: 16px;
  cursor: pointer;
  padding: 10px 20px;
  position: absolute;
  top: 20px; /* Positioned at the top of the blog card */
  left: 20px;
  transition: color 0.3s ease;
  z-index: 10;
}

.back-btn:hover {
  color: #1e293b;
}

/* ✅ Simple Loader Text */
.simple-loader {
  font-size: 20px;
  color: #007bff;
  text-align: center;
  margin-top: 20px;
  font-weight: 500;
}

/* ✅ Placeholder Loader */
.blog-placeholder {
  width: 100%;
  height: 250px;
  background: #f0f0f0;
  border-radius: 8px;
  display: flex;
  justify-content: center;
  align-items: center;
}

/* ✅ Custom Image Loader */
.blog-card img {
  width: 100%;
  height: 180px;
  object-fit: cover;
}

/* ✅ Loader Spinner */
.large-spinner {
  font-size: 40px;
  color: #007bff;
  animation: spin 1s linear infinite;
}

@keyframes spin {
  0% { transform: rotate(0deg); }
  100% { transform: rotate(360deg); }
}



