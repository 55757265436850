/* Timeline container */
.timeline-container {
  display: flex;
  align-items: center;
  justify-content: space-between;
  margin-top: 20px;
  margin-bottom: 10px;
  position: relative;
}

/* Each step in the timeline */
.timeline-step {
  display: flex;
  flex-direction: column;
  align-items: center;
  position: relative;
  flex: 1;
}

/* Connector line */
.timeline-connector {
  position: absolute;
  top: 50%;
  left: -50%;
  height: 4px;
  width: 100%;
  background-color: #ddd;
  z-index: 0;
  transition: background-color 0.3s;
}

.timeline-connector.highlighted {
  background-color: #0078d4; /* Highlighted color */
}

/* Step circle */
.timeline-circle {
  width: 20px;
  height: 20px;
  border-radius: 50%;
  background-color: #ddd;
  border: 2px solid #ddd;
  z-index: 1;
  transition: background-color 0.3s, border-color 0.3s;
}

.timeline-circle.highlighted {
  background-color: #0078d4; /* Highlighted background */
  border-color: #005bb5; /* Highlighted border */
}

/* Step label */
.timeline-label {
  margin-top: 10px;
  font-size: 12px;
  color: #888;
  text-align: center;
  transition: color 0.3s;
}

.timeline-label.highlighted {
  color: #0078d4; /* Highlighted text */
  font-weight: bold;
}

/* Responsive design for smaller screens */
@media (max-width: 600px) {
  .timeline-container {
    flex-direction: column;
    align-items: flex-start;
  }

  .timeline-step {
    margin-bottom: 20px;
  }

  .timeline-connector {
    display: none;
  }
}
