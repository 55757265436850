/* General Course Container */
.course-container {
  padding: 20px;
  max-width: 1200px;
  margin: auto;
  background: linear-gradient(135deg, #f4f7fc 50%, #ffffff 50%);
  border-radius: 15px;
}

/* Course Title */
.course-title {
  text-align: center;
  font-size: 2.5rem;
  font-weight: 700;
  margin-bottom: 30px;
  color: #3a3a3a;
}

/* Search and Filter Section */
.search-filter-section {
  display: flex;
  justify-content: space-between;
  align-items: center;
  gap: 15px;
  margin-bottom: 20px;
  padding: 15px;
  background: #fff;
  border-radius: 8px;
  box-shadow: 0 2px 10px rgba(0, 0, 0, 0.1);
}

.search-bar,
.filter-dropdown {
  display: flex;
  align-items: center;
  gap: 8px;
}

.search-bar input,
.filter-dropdown select {
  padding: 12px;
  border: 2px solid #ccc;
  border-radius: 8px;
  font-size: 14px;
  width: 250px;
  transition: border-color 0.3s ease;
}

.search-bar input:focus,
.filter-dropdown select:focus {
  border-color: #007bff;
  outline: none;
}

/* Course List */
.course-list {
  display: grid;
  grid-template-columns: repeat(auto-fit, minmax(320px, 1fr));
  gap: 25px;
  margin-top: 20px;
}

/* Course Card */
.course-card {
  background: #ffffff;
  border-radius: 12px;
  box-shadow: 0 6px 18px rgba(0, 0, 0, 0.12);
  padding: 20px;
  text-align: center;
  cursor: pointer;
  transition: all 0.3s ease-in-out;
}

.course-card:hover {
  transform: translateY(-10px) scale(1.05);
  box-shadow: 0 12px 24px rgba(0, 0, 0, 0.15);
}

.course-image {
  width: 100%;
  height: 200px;
  object-fit: cover;
  border-radius: 8px;
  transition: transform 0.3s ease-in-out;
}

.course-card:hover .course-image {
  transform: scale(1.05);
}

/* Modal Overlay */
.modal-overlay {
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background: rgba(0, 0, 0, 0.7);
  display: flex;
  justify-content: center;
  align-items: center;
  z-index: 1000;
  animation: fadeIn 0.5s ease-out;
}

/* Modal Content */
.modal-content {
  background: #ffffff;
  padding: 30px;
  width: 50%;
  max-width: 600px;
  border-radius: 12px;
  box-shadow: 0 5px 15px rgba(0, 0, 0, 0.15);
  position: relative;
  animation: popIn 0.5s ease-out;
  text-align: center;
}

/* Close Button */
.close-btn {
  position: absolute;
  top: 10px;
  right: 15px;
  background: none;
  border: none;
  font-size: 22px;
  cursor: pointer;
  color: #e74c3c;
  transition: color 0.2s ease;
}

.close-btn:hover {
  color: #c0392b;
}

/* Course Description List */
.course-description-list {
  text-align: left;
  margin-top: 15px;
  padding: 15px;
  background: #f1f1f1;
  border-radius: 8px;
  box-shadow: inset 0 0 5px rgba(0, 0, 0, 0.1);
  max-height: 250px;
  overflow-y: auto;
  list-style: none;
}

.course-description-list li {
  font-size: 16px;
  line-height: 1.8;
  color: #333;
  margin-bottom: 10px;
  display: flex;
  align-items: center;
}

.list-icon {
  color: #27ae60;
  font-size: 18px;
  margin-right: 8px;
}

/* Apply Button */
.apply-btn {
  background: #e74c3c;
  color: white;
  padding: 12px 18px;
  border-radius: 6px;
  font-size: 16px;
  border: none;
  cursor: pointer;
  transition: background 0.3s ease;
  display: block;
  width: 100%;
  margin-top: 20px;
}

.apply-btn:hover {
  background: #c0392b;
}

/* Pagination Controls */
.pagination-controls {
  display: flex;
  justify-content: center;
  align-items: center;
  margin-top: 25px;
  gap: 15px;
}

.pagination-btn {
  background: #3498db;
  color: white;
  padding: 12px 18px;
  font-size: 16px;
  border: none;
  border-radius: 8px;
  cursor: pointer;
  transition: all 0.3s ease;
}

.pagination-btn:hover {
  background: #2980b9;
  transform: scale(1.05);
}

.pagination-btn:disabled {
  background: #95a5a6;
  cursor: not-allowed;
}

/* Page Number */
.pagination-page {
  font-size: 18px;
  font-weight: bold;
}

/* Animations */
@keyframes fadeIn {
  from { opacity: 0; }
  to { opacity: 1; }
}

@keyframes popIn {
  from { transform: scale(0.8); opacity: 0; }
  to { transform: scale(1); opacity: 1; }
}

/* Responsive Styles */
@media (max-width: 768px) {
  .modal-content {
    width: 80%;
  }

  .course-list {
    grid-template-columns: 1fr;
  }

  .search-filter-section {
    flex-direction: column;
    align-items: stretch;
  }

  .search-bar input,
  .filter-dropdown select {
    width: 100%;
  }
}

/* Loader Styles */
.loader {
  display: flex;
  justify-content: center;
  align-items: center;
  font-size: 16px;
  font-weight: bold;
  color: #ffffff;
  background-color: rgba(0, 0, 0, 0.7);
  width: 50px;
  height: 50px;
  border-radius: 50%;
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
}

/* Spinner Animation */
.loader::after {
  content: "";
  width: 40px;
  height: 40px;
  border: 4px solid transparent;
  border-top: 4px solid #ffffff;
  border-radius: 50%;
  animation: spin 0.8s linear infinite;
}

@keyframes spin {
  0% { transform: rotate(0deg); }
  100% { transform: rotate(360deg); }
}

/* Image Container (Ensure Loader is Positioned Correctly) */
.image-container {
  position: relative;
  width: 100%;
  height: 200px;
  background: #f0f0f0;
  display: flex;
  justify-content: center;
  align-items: center;
  overflow: hidden;
}

/* Ensure Image Takes Full Space */
.image-container img {
  width: 100%;
  height: 100%;
  object-fit: cover;
  display: block;
}

/* Hide Image Until Fully Loaded */
.image-container img.hidden {
  display: none;
}

