/* GraphDisplay.css */
.graph-display-container {
    margin-top: 20px;
    display: flex;
    flex-wrap: wrap;
    gap: 20px;
    justify-content: center;
  }
  
  .graph-item {
    flex: 1 1 45%;
    border: 1px solid #ddd;
    border-radius: 8px;
    background-color: #fafafa;
    padding: 10px;
    box-shadow: 0 2px 5px rgba(0, 0, 0, 0.1);
    text-align: center;
  }
  
  .graph-item h3 {
    font-size: 16px;
    margin-bottom: 10px;
  }
  
  .graph-canvas {
    height: 300px;
  }
  
  .graph-display-empty {
    text-align: center;
    font-size: 14px;
    color: #666;
    margin-top: 20px;
    padding: 20px;
  }
  