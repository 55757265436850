/* General container styling */
.project-services-container {
  width: 90%;
  margin: 0 auto;
  font-family: Arial, sans-serif;
}

.project-services-container h1 {
  text-align: center;
  margin-bottom: 20px;
}

/* Actions section */
.actions {
  display: flex;
  justify-content: space-between;
  align-items: center;
  margin-bottom: 20px;
}

.search-container {
  display: flex;
  align-items: center;
}

.search-container input {
  padding: 5px;
  font-size: 16px;
  border: 1px solid #ccc;
  border-radius: 5px;
  margin-right: 5px;
}

.search-button {
  background-color: #007bff;
  color: white;
  border: none;
  padding: 5px 10px;
  border-radius: 5px;
  cursor: pointer;
}

.search-button:hover {
  background-color: #0056b3;
}

.add-button {
  background-color: #28a745;
  color: white;
  border: none;
  padding: 5px 10px;
  border-radius: 5px;
  cursor: pointer;
}

.add-button:hover {
  background-color: #218838;
}

/* Form styling */
.form-container {
  background-color: #f9f9f9;
  padding: 20px;
  border: 1px solid #ccc;
  border-radius: 10px;
  margin-bottom: 20px;
  max-width: 600px;
  width: 100%;
  box-sizing: border-box;
}

.form-container input,
.form-container textarea {
  width: 100%;
  padding: 10px;
  margin-bottom: 10px;
  border: 1px solid #ccc;
  border-radius: 5px;
  font-size: 16px;
}

.image-preview-container {
  position: relative;
  text-align: center;
  margin-bottom: 10px;
}

.image-preview {
  max-width: 100%;
  height: auto;
  border: 1px solid #ccc;
  border-radius: 5px;
  transition: transform 0.3s ease-in-out;
}

.zoom-controls {
  display: flex;
  justify-content: center;
  margin-top: 10px;
}

.zoom-button {
  background-color: #007bff;
  color: white;
  border: none;
  padding: 5px 15px;
  border-radius: 5px;
  cursor: pointer;
  margin: 0 10px;
  font-size: 14px;
  transition: background-color 0.3s ease;
}

.zoom-button:hover {
  background-color: #0056b3;
}

/* Buttons styling */
.buttons {
  display: flex;
  justify-content: space-between;
}

.form-submit-button {
  background-color: #007bff;
  color: white;
  border: none;
  padding: 12px;
  border-radius: 5px;
  cursor: pointer;
  width: 48%;
}

.form-submit-button:hover {
  background-color: #0056b3;
}

.cancel-button {
  background-color: #f8f9fa;
  color: #007bff;
  border: 1px solid #007bff;
  padding: 12px;
  border-radius: 5px;
  cursor: pointer;
  width: 48%;
}

.cancel-button:hover {
  background-color: #e2e6ea;
  color: #0056b3;
  border-color: #0056b3;
}

/* Table styling */
table {
  width: 100%;
  border-collapse: collapse;
  margin-bottom: 20px;
}

table th,
table td {
  border: 1px solid #ccc;
  padding: 10px;
  text-align: left;
}

table th {
  background-color: #f2f0f8;
}

.project-image {
  max-width: 100px;
  height: auto;
  border-radius: 5px;
}

.edit-button,
.delete-button {
  background-color: #007bff;
  color: white;
  border: none;
  padding: 5px 10px;
  border-radius: 5px;
  cursor: pointer;
  margin-right: 5px;
}

.edit-button:hover {
  background-color: #0056b3;
}

.delete-button {
  background-color: #dc3545;
}

.delete-button:hover {
  background-color: #c82333;
}

/* Pagination styling */
.pagination {
  display: flex;
  justify-content: center;
  align-items: center;
  gap: 10px;
}

.pagination button {
  background-color: #007bff;
  color: white;
  border: none;
  padding: 5px 10px;
  border-radius: 5px;
  cursor: pointer;
}

.pagination button:disabled {
  background-color: #ccc;
  cursor: not-allowed;
}

.pagination button:hover:not(:disabled) {
  background-color: #0056b3;
}

.pagination span {
  font-size: 16px;
}
