/* General Styling */
.user-manage {
    max-width: 1200px;
    margin: 20px auto;
    font-family: Arial, sans-serif;
  }
  
  .header {
    text-align: center;
    margin-bottom: 20px;
    font-size: 24px;
    color: white;
    background-color: #007bff;
    padding: 10px;
    border-radius: 5px;
  }
  
  /* Controls Styling */
  .controls {
    display: flex;
    justify-content: space-between;
    align-items: center;
    margin-bottom: 20px;
  }
  
  .search-container {
    display: flex;
    align-items: center;
    gap: 10px;
  }
  
  .small-input {
    padding: 6px;
    font-size: 14px;
    width: 200px;
  }
  
  .search-button {
    padding: 6px 10px;
    background-color: #007bff;
    color: white;
    border: none;
    border-radius: 4px;
    cursor: pointer;
  }
  
  .search-button:hover {
    background-color: #0056b3;
  }
  
  .page-size-selector {
    padding: 6px;
    font-size: 14px;
    width: 80px;
  }
  
  /* Table Styling */
  .user-table {
    width: 100%;
    border-collapse: collapse;
  }
  
  .user-table th,
  .user-table td {
    padding: 10px;
    text-align: left;
    border: 1px solid #ccc;
  }
  
  .user-table th {
    background-color: #007bff;
    color: white;
    font-weight: bold;
  }
  
  .active-status {
    color: green;
    font-weight: bold;
  }
  
  .inactive-status {
    color: red;
    font-weight: bold;
  }
  
  .edit-button,
  .toggle-button {
    padding: 6px 10px;
    margin-right: 5px;
    border: none;
    border-radius: 4px;
    cursor: pointer;
  }
  
  .edit-button {
    background-color: #ffc107;
    color: black;
  }
  
  .edit-button:hover {
    background-color: #e0a800;
  }
  
  .save-button {
    background-color: #28a745;
    color: white;
  }
  
  .save-button:hover {
    background-color: #218838;
  }
  
  button.cancel-button {
    background-color: #dc3545;
    color: #2c3e50;
    border: 1px solid #007bff;
    padding: 12px;
    margin: 10px;
    border-radius: 5px;
    cursor: pointer;
    width: auto;
}
  
  .cancel-button:hover {
    background-color: #c82333;
  }
  
  .toggle-button.activate {
    background-color: #28a745;
    color: white;
  }
  
  .toggle-button.deactivate {
    background-color: #dc3545;
    color: white;
  }
  
  .toggle-button:hover {
    opacity: 0.9;
  }
  
  /* Pagination */
  .pagination {
    display: flex;
    justify-content: center;
    gap: 10px;
    margin-top: 20px;
  }
  
  .pagination button {
    padding: 6px 12px;
    background-color: #007bff;
    color: white;
    border: none;
    border-radius: 4px;
  }
  
  .pagination button:disabled {
    background-color: #ccc;
    cursor: not-allowed;
  }

  .header-filter {
    margin-top: 5px;
    width: 90%;
    padding: 4px;
    font-size: 12px;
    border: 1px solid #ccc;
    border-radius: 4px;
  }
  
  .header-filter select {
    padding: 4px;
  }
  