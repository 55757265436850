/* ✅ General Styling */
body {
  font-family: "Arial", sans-serif;
  background: #f4f4f4;
  color: #333;
  margin: 0;
  padding: 0;
}

.course-container {
  max-width: 1200px;
  margin: auto;
  padding: 20px;
  background: #fff;
  border-radius: 10px;
  box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1);
}

h1.course-title {
  text-align: center;
  color: #d63031;
  font-size: 28px;
  margin-bottom: 20px;
}

/* ✅ Course Form Styling */
.card {
  background: #ffffff;
  padding: 20px;
  border-radius: 10px;
  box-shadow: 0 4px 10px rgba(0, 0, 0, 0.1);
  margin-bottom: 20px;
  border: 2px solid #e74c3c;
}

.input, .textarea {
  width: 100%;
  padding: 12px;
  margin-bottom: 12px;
  border: 2px solid #ddd;
  border-radius: 8px;
  font-size: 16px;
  transition: 0.3s;
  background: #fff;
}

.textarea {
  min-height: 150px;
  resize: vertical;
}

.input:focus, .textarea:focus {
  border-color: #e74c3c;
  outline: none;
  box-shadow: 0 0 5px rgba(231, 76, 60, 0.4);
}

/* ✅ Add & Update Buttons */
.btn.course-btn {
  width: 100%;
  padding: 12px;
  font-size: 18px;
  font-weight: bold;
  text-transform: uppercase;
  border-radius: 8px;
  cursor: pointer;
  transition: 0.3s;
  display: block;
  text-align: center;
  border: none;
  outline: none;
}

.course-btn.add {
  background: linear-gradient(135deg, #e74c3c, #c0392b);
  color: white;
  box-shadow: 0px 4px 10px rgba(231, 76, 60, 0.4);
}

.course-btn.add:hover {
  background: linear-gradient(135deg, #c0392b, #a93226);
  box-shadow: 0px 6px 12px rgba(231, 76, 60, 0.6);
}

.course-btn.update {
  background: linear-gradient(135deg, #f39c12, #e67e22);
  color: white;
  box-shadow: 0px 4px 10px rgba(241, 196, 15, 0.4);
}

.course-btn.update:hover {
  background: linear-gradient(135deg, #e67e22, #d35400);
  box-shadow: 0px 6px 12px rgba(241, 196, 15, 0.6);
}

/* ✅ Edit & Delete Buttons */
.btn.edit {
  background: linear-gradient(135deg, #27ae60, #2ecc71);
  color: white;
  padding: 10px 12px;
  font-size: 16px;
  font-weight: bold;
  border-radius: 6px;
  cursor: pointer;
  transition: 0.3s;
  border: none;
}

.btn.edit:hover {
  background: linear-gradient(135deg, #219150, #25a55b);
  box-shadow: 0px 6px 12px rgba(39, 174, 96, 0.5);
}

.btn.delete {
  background: linear-gradient(135deg, #e74c3c, #c0392b);
  color: white;
  padding: 10px 12px;
  font-size: 16px;
  font-weight: bold;
  border-radius: 6px;
  cursor: pointer;
  transition: 0.3s;
  border: none;
}

.btn.delete:hover {
  background: linear-gradient(135deg, #c0392b, #a93226);
  box-shadow: 0px 6px 12px rgba(231, 76, 60, 0.5);
}

/* ✅ Image Preview */
.image-preview {
  display: block;
  width: 150px;
  height: auto;
  border-radius: 8px;
  margin-top: 10px;
}

/* ✅ Word Count Styling */
.word-count {
  font-size: 14px;
  color: #555;
  text-align: right;
}

/* ✅ Search Box */
.search-container {
  display: flex;
  gap: 10px;
  justify-content: center;
  margin-bottom: 20px;
}

.search-btn {
  background: #e74c3c;
}

.search-btn:hover {
  background: #c0392b;
}

/* ✅ Table */
.course-table-container {
  overflow-x: auto;
}

.table {
  width: 100%;
  border-collapse: collapse;
  background: #fff;
  margin-top: 20px;
}

.table th, .table td {
  padding: 12px;
  border-bottom: 1px solid #ddd;
  text-align: left;
}

.table th {
  background: #e74c3c;
  color: white;
}

.table td img.course-image-thumbnail {
  width: 50px;
  height: 50px;
  border-radius: 5px;
}

/* ✅ Pagination */
.pagination {
  display: flex;
  justify-content: center;
  gap: 15px;
  margin-top: 20px;
}

.pagination button {
  padding: 10px 15px;
  border: none;
  background: #e74c3c;
  color: #fff;
  cursor: pointer;
  border-radius: 5px;
  transition: 0.3s;
}

.pagination button:disabled {
  background: #bdc3c7;
  cursor: not-allowed;
}

/* ✅ Responsive Design */
@media (max-width: 768px) {
  .input, .textarea, .btn {
    font-size: 14px;
  }

  .search-container {
    flex-direction: column;
    gap: 5px;
  }

  .table th, .table td {
    font-size: 14px;
  }
}
