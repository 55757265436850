/* General Page Styling */
body {
    font-family: 'Arial', sans-serif;
    margin: 0;
    padding: 0;
    background-color: #f9fafc;
  }
  
  /* Container for Contact Details */
  .contact-container {
   
    margin: 40px auto;
    padding: 20px;
    background-color: #ffffff;
    box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1);
    border-radius: 8px;
  }
  
  /* Title Styling */
  .title {
    font-size: 2em;
    text-align: center;
    margin-bottom: 20px;
    color: #333;
  }
  
  /* Loading and Error State */
  .loading, .error, .no-contacts {
    text-align: center;
    font-size: 1.2em;
    margin-top: 20px;
  }
  
  /* Table Styling */
  .contact-table {
    width: 100%;
    border-collapse: collapse;
    margin-top: 20px;
  }
  
  .contact-table thead {
    background-color: #4caf50;
    color: #fff;
  }
  
  .contact-table th, .contact-table td {
    text-align: left;
    padding: 12px 15px;
    border-bottom: 1px solid #ddd;
  }
  
  .contact-table tr:nth-child(even) {
    background-color: #f2f2f2;
  }
  
  /* Hover Effect */
  .contact-table tr:hover {
    background-color: #f5f5f5;
  }
  
  /* Delete Button Styling */
  .delete-btn {
    background-color: #ff4d4f;
    color: #fff;
    border: none;
    border-radius: 4px;
    padding: 6px 12px;
    cursor: pointer;
    font-size: 0.9em;
  }
  
  .delete-btn:hover {
    background-color: #d9363e;
  }
  
  /* Responsive Design */
  @media (max-width: 768px) {
    .contact-container {
      padding: 10px;
    }
  
    .title {
      font-size: 1.5em;
    }
  
    .contact-table th, .contact-table td {
      padding: 8px;
      font-size: 0.9em;
    }
  
    .delete-btn {
      padding: 4px 8px;
      font-size: 0.8em;
    }
  }
  
  @media (max-width: 480px) {
    .contact-container {
      margin: 10px;
    }
  
    .title {
      font-size: 1.2em;
    }
  
    .contact-table {
      font-size: 0.8em;
    }
  
    .delete-btn {
      font-size: 0.7em;
    }
  }
  