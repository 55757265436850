.apply-form-wrapper {
  padding: 2rem;
  background-color: #f8f9fa;
  min-height: 100vh;
  display: flex;
  flex-direction: column;
  align-items: center;
}

.apply-form-container {
  background: white;
  padding: 2rem;
  border-radius: 8px;
  width: 100%;
  max-width: 600px;
  box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1);
}

.form-group {
  margin-bottom: 1rem;
}

.form-group label {
  display: block;
  font-size: 1rem;
  font-weight: bold;
  margin-bottom: 0.5rem;
  color: #333;
}

.form-group input,
.form-group textarea {
  width: 100%;
  padding: 0.8rem;
  border: 1px solid #ddd;
  border-radius: 4px;
  font-size: 1rem;
  transition: border-color 0.3s ease;
}

textarea {
  resize: vertical;
}

.form-group input:focus,
.form-group textarea:focus {
  border-color: #457b9d;
  outline: none;
  box-shadow: 0 0 4px rgba(69, 123, 157, 0.5);
}

.error-text {
  color: #e63946;
  font-size: 0.9rem;
  margin-top: 0.3rem;
}

.captcha-box {
  display: flex;
  align-items: center;
  margin-bottom: 0.5rem;
}

.captcha-text {
  font-size: 1.2rem;
  font-weight: bold;
  color: #333;
  margin-right: 1rem;
  background: #f1f1f1;
  padding: 0.5rem 1rem;
  border-radius: 4px;
}

.refresh-captcha {
  background: #457b9d;
  color: white;
  border: none;
  border-radius: 4px;
  padding: 0.5rem 1rem;
  cursor: pointer;
  font-size: 1rem;
}

.refresh-captcha:hover {
  background: #1d3557;
}

.submit-button {
  padding: 0.8rem 1.5rem;
  background-color: #1d3557;
  color: white;
  font-size: 1rem;
  border: none;
  border-radius: 5px;
  cursor: pointer;
  transition: background-color 0.3s ease, transform 0.2s ease;
}

.submit-button:hover {
  background-color: #457b9d;
}

.submit-button:active {
  transform: scale(0.98);
}

.submit-button:disabled {
  background-color: #ccc;
  cursor: not-allowed;
}

.submit-button {
  cursor: pointer;
  opacity: 1;
}
.submit-button[disabled] {
  cursor: not-allowed;
  opacity: 0.6;
}
