/* AboutCompany.css */
.about-company {
    padding: 60px 20px;
    background: #f9fafc;
    text-align: center;
  }
  
  .section-title {
    font-size: 2.5rem;
    color: #007bff;
    margin-bottom: 20px;
    font-weight: bold;
  }
  
  .section-content {
    font-size: 1.1rem;
    color: #555;
    line-height: 1.8;
    margin-bottom: 20px;
  }
  
  .expanded-content {
    margin-top: 20px;
    font-size: 1rem;
    color: #666;
    line-height: 1.6;
  }
  
  .read-more-button {
    margin-top: 20px;
    background: #007bff;
    color: white;
    border: none;
    padding: 10px 20px;
    border-radius: 5px;
    cursor: pointer;
    font-size: 1rem;
    transition: background 0.3s ease;
  }
  
  .read-more-button:hover {
    background: #0056b3;
  }
  
  .about-highlights {
    display: flex;
    justify-content: center;
    gap: 30px;
    margin-top: 30px;
    flex-wrap: wrap;
  }
  
  .highlight-item {
    background: #ffffff;
    padding: 20px;
    border-radius: 10px;
    box-shadow: 0 4px 10px rgba(0, 0, 0, 0.1);
    text-align: center;
    transition: transform 0.3s ease;
    width: 200px;
  }
  
  .highlight-item:hover {
    transform: translateY(-5px);
  }
  
  .highlight-item h3 {
    font-size: 2rem;
    color: #007bff;
    margin-bottom: 5px;
  }
  
  .highlight-item p {
    font-size: 1rem;
    color: #555;
  }
  
  /* Responsive Design */
  @media (max-width: 768px) {
    .about-highlights {
      flex-direction: column;
      gap: 15px;
    }
  
    .highlight-item {
      width: 80%;
    }
  }
  
  @media (max-width: 480px) {
    .section-title {
      font-size: 2rem;
    }
  
    .section-content {
      font-size: 1rem;
    }
  
    .read-more-button {
      font-size: 0.9rem;
      padding: 8px 16px;
    }
  }
  