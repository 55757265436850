/* General Styles */
.job-list-container {
  padding: 1.5rem;
}

.job-list {
  display: grid;
  grid-template-columns: 1fr;
  gap: 1rem;
}

@media (min-width: 1200px) {
  .job-list {
    grid-template-columns: 1fr 1fr;
  }
}



.job-card:hover {
  box-shadow: 0px 6px 14px rgba(0, 0, 0, 0.2);
  transform: scale(1.02);
}

/* Job Title */
.job-card h3 {
  text-align: center;
  font-size: 1.8rem;
  font-weight: bold;
  color: #1d3557;
  animation: bounce 2s infinite alternate;
}

@keyframes bounce {
  0% {
    transform: translateY(0);
  }
  100% {
    transform: translateY(-5px);
  }
}

/* Job Description */
.job-description {
  margin-top: 1rem;
  max-height: 100px;
  overflow: hidden;
  transition: max-height 0.5s ease;
}

.job-description.expanded {
  max-height: none;
}

/* Description List */
.job-description ul {
  padding-left: 1rem;
}

.job-description ul li {
  margin: 0.4rem 0;
  font-size: 0.95rem;
}

/* Skeleton Loader */
.skeleton {
  background: #e0e0e0;
  padding: 1.5rem;
  border-radius: 8px;
}

.skeleton-title {
  height: 20px;
  width: 60%;
  background: #bdbdbd;
  margin-bottom: 10px;
}

.skeleton-text {
  height: 15px;
  width: 90%;
  background: #bdbdbd;
  margin-bottom: 8px;
}

.skeleton-button {
  height: 30px;
  width: 40%;
  background: #bdbdbd;
  border-radius: 4px;
}

/* Read More / Read Less */
.toggle-description {
  margin-top: 0.5rem;
  color: #1d3557;
  font-weight: bold;
  cursor: pointer;
  display: inline-block;
}

.apply-button {
  margin-top: 1rem;
  padding: 0.6rem 1.2rem;
  background-color: #457b9d;
  color: white;
  border: none;
  border-radius: 4px;
}
