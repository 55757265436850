/* About Company Details Section */
.company-overview {
  background: linear-gradient(135deg, #4f8ef7, #1d4ed8);
  color: #ffffff;
  padding: 60px 40px;
  border-radius: 16px;
  margin: 30px auto;
  text-align: center;
  box-shadow: 0 10px 25px rgba(0, 0, 0, 0.2);
  font-family: 'Poppins', sans-serif;
  animation: fadeIn 1.2s ease-in-out;
  position: relative;
  overflow: hidden;
}

/* Terms of Use Section */
.terms-of-use {
  background: #f9fafb;
  color: #333;
  padding: 50px 30px;
  border-radius: 12px;
  margin: 40px auto;
  font-family: 'Poppins', sans-serif;
  text-align: left;
  line-height: 1.8;
  box-shadow: 0 8px 20px rgba(0, 0, 0, 0.1);
}

.terms-of-use h1 {
  font-size: 2.4rem;
  margin-bottom: 20px;
  font-weight: bold;
  color: #1d4ed8;
}

.terms-of-use h2 {
  font-size: 1.8rem;
  margin-top: 30px;
  margin-bottom: 15px;
  font-weight: 600;
  color: #2563eb;
}

.terms-of-use p {
  font-size: 1rem;
  margin-bottom: 15px;
  color: #555;
}

.terms-of-use ul {
  margin: 20px 0;
  padding-left: 20px;
  list-style-type: disc;
}

.terms-of-use ul li {
  font-size: 1rem;
  margin-bottom: 10px;
}

.terms-of-use a {
  color: #2563eb;
  text-decoration: underline;
}

.terms-of-use a:hover {
  text-decoration: none;
}

/* Additional Terms of Use Content */
.additional-content {
  margin-top: 40px;
  padding: 30px;
  background: #eef2ff;
  border-radius: 12px;
  box-shadow: 0 6px 15px rgba(0, 0, 0, 0.1);
}

.additional-content h3 {
  font-size: 1.6rem;
  margin-bottom: 15px;
  font-weight: 600;
  color: #1e40af;
}

.additional-content p {
  font-size: 1rem;
  margin-bottom: 10px;
  color: #444;
}

.additional-content ul {
  margin: 20px 0;
  padding-left: 20px;
  list-style-type: circle;
}

.additional-content ul li {
  font-size: 1rem;
  margin-bottom: 10px;
  color: #555;
}

/* PDF Download Section */
.pdf-download {
  margin-top: 30px;
  text-align: center;
}

.pdf-download a {
  display: inline-block;
  background: #1d4ed8;
  color: #fff;
  font-size: 1rem;
  font-weight: 600;
  padding: 10px 20px;
  border-radius: 8px;
  text-decoration: none;
  transition: background 0.3s ease-in-out;
}

.pdf-download a:hover {
  background: #2563eb;
}

/* Animated Floating Background Effect */
.company-overview::before {
  content: '';
  position: absolute;
  top: -60%;
  left: -60%;
  width: 220%;
  height: 220%;
  background: radial-gradient(circle, rgba(255, 255, 255, 0.1) 30%, transparent 70%);
  animation: floatingBackground 20s infinite linear;
  z-index: 0;
  opacity: 0.3;
}

.company-overview h2 {
  font-size: 2.5rem;
  margin-bottom: 20px;
  text-transform: uppercase;
  font-weight: 700;
  letter-spacing: 1.2px;
  color: #ffffff;
  position: relative;
  z-index: 1;
  animation: slideInFromTop 1s ease-in-out;
}

.company-overview p {
  font-size: 1.1rem;
  margin-bottom: 20px;
  line-height: 1.6;
  font-weight: 400;
  position: relative;
  z-index: 1;
  animation: fadeInUp 1s ease-in-out;
}

/* Company Details List */
.company-overview ul {
  list-style: none;
  padding: 0;
  margin: 25px 0;
  position: relative;
  z-index: 1;
  animation: fadeInUp 1.2s ease-in-out;
}

.company-overview ul li {
  font-size: 1rem;
  margin: 10px 0;
  display: flex;
  align-items: center;
  font-weight: 500;
  gap: 12px;
}

.company-overview ul li::before {
  content: '🏢';
  color: #ffd700;
  font-size: 1.4rem;
  animation: popIn 0.8s ease-in-out;
}

.company-overview ul li strong {
  color: #ffde59;
  font-weight: 600;
}

/* Final Paragraph */
.company-overview p:last-child {
  font-size: 1rem;
  font-weight: 600;
  margin-top: 30px;
  animation: fadeIn 1.2s ease-in-out;
}

/* Hover Effects */
.company-overview:hover {
  transform: translateY(-5px);
  transition: all 0.4s ease-in-out;
  box-shadow: 0 15px 30px rgba(0, 0, 0, 0.25);
}

/* Responsive Design */
@media (max-width: 768px) {
  .company-overview {
    padding: 30px 20px;
  }
  .terms-of-use h1 {
    font-size: 2rem;
  }
  .terms-of-use h2 {
    font-size: 1.5rem;
  }
  .terms-of-use p, .terms-of-use ul li {
    font-size: 0.9rem;
  }
  .pdf-download a {
    font-size: 0.9rem;
    padding: 8px 16px;
  }
}
