/* Container Styling */
.manage-news-container {
  padding: 20px;
  font-family: Arial, sans-serif;
  max-width: 1200px;
  margin: 0 auto;
  background: #f9f9f9;
  border-radius: 10px;
  box-shadow: 0 4px 6px rgba(0, 0, 0, 0.1);
}

/* Header Styling */
h1 {
  text-align: center;
  margin-bottom: 20px;
  color: #2c3e50;
}

/* Actions Section */
.actions {
  display: flex;
  justify-content: space-between;
  align-items: center;
  margin-bottom: 20px;
  gap: 10px;
}

/* Search Container */
.search-container {
  display: flex;
  align-items: center;
  gap: 5px;
}

.search-container input {
  padding: 8px 10px;
  font-size: 14px;
  border: 1px solid #ccc;
  border-radius: 5px;
  width: 200px;
  transition: border-color 0.3s ease, box-shadow 0.3s ease;
}

.search-container input:focus {
  border-color: #3498db;
  box-shadow: 0 0 5px rgba(52, 152, 219, 0.5);
}

.search-button {
  background-color: #3498db;
  color: white;
  border: none;
  padding: 8px 12px;
  border-radius: 5px;
  cursor: pointer;
  transition: all 0.3s ease;
}

.search-button:hover {
  background-color: #2980b9;
}

/* Add Button */
.add-button {
  padding: 10px 20px;
  font-size: 16px;
  background: linear-gradient(45deg, #ff7e5f, #feb47b);
  color: white;
  border: none;
  border-radius: 25px;
  cursor: pointer;
  transition: all 0.3s ease;
  display: flex;
  align-items: center;
  gap: 8px;
  box-shadow: 0 4px 8px rgba(0, 0, 0, 0.2);
}

.add-button:hover {
  background: linear-gradient(45deg, #feb47b, #ff7e5f);
  transform: scale(1.05);
}

.add-button:active {
  transform: scale(0.95);
}

/* Form Container */
.form-container {
  margin-bottom: 20px;
  background: white;
  padding: 20px;
  border-radius: 8px;
  box-shadow: 0 4px 6px rgba(0, 0, 0, 0.1);
}

.form-container input,
.form-container textarea {
  width: 100%;
  padding: 12px 15px;
  margin-bottom: 10px;
  font-size: 16px;
  border: 1px solid #ccc;
  border-radius: 5px;
  transition: border-color 0.3s ease, box-shadow 0.3s ease;
}

.form-container input:focus,
.form-container textarea:focus {
  border-color: #3498db;
  box-shadow: 0 0 5px rgba(52, 152, 219, 0.5);
}

.form-submit-button {
  padding: 10px 20px;
  font-size: 16px;
  background: linear-gradient(45deg, #6a11cb, #2575fc);
  color: white;
  border: none;
  border-radius: 25px;
  cursor: pointer;
  transition: all 0.3s ease;
  width: 100%;
  text-align: center;
}

.form-submit-button:hover {
  background: linear-gradient(45deg, #2575fc, #6a11cb);
  transform: scale(1.05);
}

.form-submit-button:active {
  transform: scale(0.95);
}

/* Table Styling */
table {
  width: 100%;
  border-collapse: collapse;
  background: white;
  margin-bottom: 20px;
  border-radius: 8px;
  overflow: hidden;
  box-shadow: 0 4px 6px rgba(0, 0, 0, 0.1);
}

table th,
table td {
  padding: 12px 15px;
  text-align: left;
  border-bottom: 1px solid #ddd;
  font-size: 16px;
  color: #2c3e50;
}

table th {
  background: #3498db;
  
  text-align: left;
}

table tr:hover {
  background-color: #f2f2f2;
}

/* Action Buttons */
.edit {
  background-color: #3498db;
  color: white;
  border: none;
  padding: 8px 12px;
  border-radius: 5px;
  cursor: pointer;
  transition: background-color 0.3s ease;
}

.edit:hover {
  background-color: #2980b9;
}

.delete {
  background-color: #e74c3c;
  color: white;
  border: none;
  padding: 8px 12px;
  border-radius: 5px;
  cursor: pointer;
  transition: background-color 0.3s ease;
}

.delete:hover {
  background-color: #c0392b;
}

/* Pagination */
.pagination {
  display: flex;
  justify-content: center;
  gap: 10px;
  margin-top: 20px;
}

.pagination button {
  padding: 8px 15px;
  font-size: 14px;
  background: white;
  border: 1px solid #3498db;
  border-radius: 5px;
  color: #3498db;
  cursor: pointer;
  transition: all 0.3s ease;
}

.pagination button:hover {
  background-color: #3498db;
  color: white;
}

.pagination button:disabled {
  background-color: #ddd;
  color: #aaa;
  cursor: not-allowed;
}

/* Responsive Design */
@media (max-width: 768px) {
  .actions {
    flex-direction: column;
    align-items: stretch;
  }

  .search-container input {
    width: 100%;
  }

  .form-container input,
  .form-container textarea {
    font-size: 14px;
  }

  .pagination button {
    padding: 6px 10px;
    font-size: 12px;
  }
}

/* Cancel Button */
.cancel-button {
  padding: 10px 20px;
  font-size: 16px;
  background: #e74c3c;
  color: white;
  border: none;
  border-radius: 25px;
  cursor: pointer;
  transition: all 0.3s ease;
  margin-left: 10px;
}

.cancel-button:hover {
  background: #c0392b;
  transform: scale(1.05);
}

.cancel-button:active {
  transform: scale(0.95);
}

/* Form Actions */
.form-actions {
  display: flex;
  justify-content: flex-end;
  gap: 10px;
}

