.center-container {
    display: flex;
    justify-content: center;
    align-items: center;
    height: 100vh;
    background-color: #f7f9fc;
  }
  
  .form-container {
    width: 100%;
    max-width: 400px;
    background: #fff;
    border-radius: 8px;
    box-shadow: 0 4px 6px rgba(0, 0, 0, 0.1);
    padding: 20px;
    text-align: center;
  }
  
  .form-header {
    margin-bottom: 20px;
  }
  
  .form-header h1 {
    margin-bottom: 10px;
    font-size: 24px;
    color: #333;
  }
  
  .form-header p {
    font-size: 14px;
    color: #666;
  }
  
  .input-group {
    position: relative;
    margin-bottom: 15px;
  }
  
  .input-group input {
    width: 100%;
    padding: 10px 40px;
    border: 1px solid #ddd;
    border-radius: 4px;
    font-size: 14px;
  }
  
  .input-group .icon {
    position: absolute;
    top: 50%;
    left: 10px;
    transform: translateY(-50%);
    color: #666;
  }
  
  .field-error {
    color: red;
    font-size: 12px;
    text-align: left;
  }
  
  .success-message {
    color: green;
    font-size: 14px;
    text-align: left;
  }
  
  .submit-btn {
    width: 100%;
    padding: 10px;
    background: #1976d2;
    color: #fff;
    border: none;
    border-radius: 4px;
    cursor: pointer;
    font-size: 16px;
    margin-top: 10px;
  }
  
  .submit-btn:hover {
    background: #145da0;
  }
  
  .signup-link {
    margin-top: 15px;
  }
  
  .signup-link a {
    color: #1976d2;
    text-decoration: none;
    font-size: 14px;
  }
  
  .signup-link a:hover {
    text-decoration: underline;
  }
  .submit-btn {
    position: relative;
  }
  
  .submit-btn:disabled {
    background-color: #ccc;
    cursor: not-allowed;
  }
  
  .loader {
    margin: 10px auto;
    border: 4px solid #f3f3f3;
    border-top: 4px solid #3498db;
    border-radius: 50%;
    width: 20px;
    height: 20px;
    animation: spin 1s linear infinite;
  }
  
  @keyframes spin {
    0% {
      transform: rotate(0deg);
    }
    100% {
      transform: rotate(360deg);
    }
  }