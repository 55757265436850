/* Back to Top Button */
.to-the-top {
    position: fixed;
    bottom: 20px;
    right: 20px;
    background-color: #007bff;
    border-radius: 50%;
    width: 50px;
    height: 50px;
    display: flex;
    justify-content: center;
    align-items: center;
    box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1);
    cursor: pointer;
    z-index: 1000;
    opacity: 0;
    transition: opacity 0.3s ease, transform 0.3s ease;
  }
  
  .to-the-top a {
    color: #ffffff;
    font-size: 1.5rem;
    text-decoration: none;
  }
  
  .to-the-top:hover {
    transform: scale(1.1);
    background-color: #0056b3;
  }
  
  .to-the-top.show {
    opacity: 1;
  }
  
  /* Ensure the button is hidden when not needed */
  .to-the-top.hide {
    opacity: 0;
    pointer-events: none;
  }
  