/* General Styling */
body {
  font-family: 'Poppins', sans-serif;
  margin: 0;
  padding: 0;
}

/* 🔹 Top Bar Styling */
.top-bar {
  font-size: 14px;
  border-bottom: 1px solid #eaeaea;
  background-color: #f8f9fa;
  padding: 8px 15px;
}

/* Contact Info - Right aligned on large screens */
.container-topbar {
  display: flex;
  justify-content: flex-end; /* Right-align on desktop */
  align-items: center;
  max-width: 1200px;
  margin: 0 auto;
}

/* 🔹 Contact Info (Phone & Email) */
.contact-info {
  display: flex;
  align-items: center;
  gap: 20px; /* Space between phone & email */
}

/* Contact items - align icon & text properly */
.contact-item {
  display: flex;
  align-items: center;
  gap: 8px; /* Space between icon and text */
}

/* Icon styling */
.contact-info i {
  font-size: 1rem;
  color: #007bff;
}

/* Phone & email text */
.contact-info a {
  font-weight: 500;
  text-decoration: none;
  color: black;
  white-space: nowrap; /* Prevents text from wrapping */
}

.contact-info a:hover {
  text-decoration: underline;
  color: #007bff;
}

/* 🔹 Navbar Styling */
.navbar {
  padding: 12px 20px;
  background-color: white;
  box-shadow: 0px 4px 6px rgba(0, 0, 0, 0.05);
}

.navbar-brand img {
  max-height: 55px;
}

/* 🔹 Navbar Links */
.navbar-nav .nav-link {
  font-size: 16px;
  font-weight: 600;
  text-transform: capitalize;
  color: #333;
  transition: color 0.3s ease-in-out;
  padding: 8px 15px;
}

.navbar-nav .nav-link:hover {
  color: #007bff;
}

/* 🔹 Navbar Toggler (Mobile View) */
.navbar-toggler {
  border: none;
  outline: none;
}

.navbar-toggler:focus {
  box-shadow: none;
}

/* 🔹 Login/Logout Button */
.auth-buttons {
  display: flex;
  align-items: center;
}

/* 🔹 Mobile Responsiveness */
@media (max-width: 992px) {
  .navbar-nav {
      text-align: center;
  }

  .navbar-nav .nav-link {
      padding: 10px;
  }

  /* Center align login button on mobile */
  .auth-buttons {
      width: 100%;
      display: flex;
      justify-content: center;
      margin-top: 10px;
  }

  .auth-buttons .btn {
      width: 80%;
      text-align: center;
      padding: 12px;
      font-size: 16px;
  }

  /* 📌 Left-align phone & email in mobile */
  .container-topbar {
      justify-content: flex-start; /* Align left */
      text-align: left;
      flex-direction: column;
      padding-left: 15px;
      gap: 8px;
  }

  .contact-info {
      flex-direction: column;
      align-items: flex-start; /* Ensure left alignment */
      gap: 5px;
  }
}

/* Quotation Icon Styling */
.quotation-icon {
  position: fixed;
  top: 20px;
  right: 30px;
  font-size: 60px;
  color: darkred;
  cursor: pointer;
  z-index: 1000;
  background-color: rgba(0, 0, 0, 0.8); /* Dark Background */
  padding: 10px;
  border-radius: 50%;
  box-shadow: 0 0 10px rgba(0, 0, 0, 0.5);
}






