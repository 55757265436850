/* Discount Generator Page Styles */
.discount-generator-container {
    max-width: 600px;
    margin: auto;
    padding: 20px;
    background-color: #ffffff;
    border-radius: 12px;
    box-shadow: 0px 8px 16px rgba(0, 0, 0, 0.1);
    text-align: center;
    font-family: 'Poppins', sans-serif;
    transition: all 0.3s ease-in-out;
}

h2 {
    font-size: 1.8rem;
    color: #333;
    font-weight: 600;
    margin-bottom: 15px;
}

.discount-form {
    display: flex;
    flex-direction: column;
    gap: 12px;
    margin-bottom: 20px;
}

.discount-form input {
    padding: 12px;
    border: 2px solid #007bff;
    border-radius: 8px;
    font-size: 1rem;
    transition: border 0.3s ease-in-out, box-shadow 0.3s ease-in-out;
    background: #fff;
    width: 100%;
    text-align: center;
    box-shadow: inset 0px 2px 4px rgba(0, 0, 0, 0.1);
}

.discount-form input:focus {
    border-color: #0056b3;
    outline: none;
    box-shadow: 0px 0px 8px rgba(0, 123, 255, 0.5);
}

/* Generate Button */
.generate-btn {
    padding: 14px;
    font-size: 1.1rem;
    font-weight: bold;
    background: linear-gradient(135deg, #007bff, #0056b3);
    color: white;
    border: none;
    border-radius: 8px;
    cursor: pointer;
    transition: all 0.3s ease-in-out;
    text-transform: uppercase;
}

.generate-btn:hover {
    background: linear-gradient(135deg, #0056b3, #003580);
    transform: scale(1.05);
}

/* Discount Table */
.discount-table {
    width: 100%;
    border-collapse: collapse;
    margin-top: 20px;
    overflow-x: auto;
}

.discount-table th, .discount-table td {
    padding: 12px;
    border-bottom: 2px solid #ddd;
    text-align: center;
}

.discount-table th {
    background: #007bff;
    color: white;
    font-weight: bold;
}

.discount-table td {
    font-size: 1rem;
    color: #444;
}

.discount-table tr:hover {
    background-color: #f1f1f1;
    transition: background-color 0.3s ease-in-out;
}

/* Responsive Design */
@media screen and (max-width: 768px) {
    .discount-generator-container {
        width: 90%;
    }

    .discount-form {
        flex-direction: column;
    }

    .generate-btn {
        width: 100%;
    }

    .discount-table {
        font-size: 0.9rem;
    }

    h2 {
        font-size: 1.5rem;
    }
}
