/* General Styling */
.user-profile {
    display: flex;
    justify-content: center;
    align-items: center;
    height: 100vh;
    background-color: #f5f6fa;
    font-family: Arial, sans-serif;
  }
  
  /* Card Styling */
  .card {
    background: white;
    border-radius: 15px;
    box-shadow: 0 8px 20px rgba(0, 0, 0, 0.1);
    max-width: 500px;
    width: 90%;
    overflow: hidden;
    animation: fadeIn 1s ease;
  }
  
  .card-header {
    background-color: #007bff;
    color: white;
    padding: 15px 20px;
    text-align: center;
    font-size: 20px;
  }
  
  .card-body {
    padding: 20px;
  }
  
  .detail-item {
    display: flex;
    justify-content: space-between;
    align-items: center;
    margin-bottom: 15px;
  }
  
  .detail-item label {
    font-weight: bold;
    color: #555;
    width: 40%;
    text-align: left;
  }
  
  .detail-item span,
  .detail-item input {
    width: 55%;
    font-size: 14px;
  }
  
  .detail-item input {
    padding: 8px;
    border: 1px solid #ddd;
    border-radius: 5px;
    outline: none;
  }
  
  /* Button Styling */
  .profile-actions,
  .edit-button {
    margin-top: 20px;
    display: flex;
    justify-content: space-around;
  }
  
  .edit-button,
  .save-button,
  .cancel-button {
    padding: 10px 20px;
    border: none;
    border-radius: 5px;
    font-size: 14px;
    cursor: pointer;
    transition: background-color 0.3s ease, transform 0.2s ease;
  }
  
  .edit-button {
    background-color: #007bff;
    color: white;
  }
  
  .save-button {
    background-color: #28a745;
    color: white;
  }
  
  .cancel-button {
    background-color: #dc3545;
    color: white;
  }
  
  .edit-button:hover {
    background-color: #0056b3;
    transform: scale(1.05);
  }
  
  .save-button:hover {
    background-color: #218838;
    transform: scale(1.05);
  }
  
  .cancel-button:hover {
    background-color: #c82333;
    transform: scale(1.05);
  }
  
  /* Animation */
  @keyframes fadeIn {
    from {
      opacity: 0;
      transform: translateY(10px);
    }
    to {
      opacity: 1;
      transform: translateY(0);
    }
  }
  
  /* Responsive Design */
  @media (max-width: 768px) {
    .card {
      width: 100%;
      margin: 10px;
    }
  
    .detail-item label {
      width: 35%;
    }
  
    .detail-item span,
    .detail-item input {
      font-size: 13px;
    }
  }
  