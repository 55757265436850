/* Dashboard.css */
.dashboard {
    background: linear-gradient(to right, #007bff, #00c6ff);
    padding: 60px 20px;
    text-align: center;
    color: white;
  }
  
  .dashboard-title {
    font-size: 2.5rem;
    margin-bottom: 40px;
    font-weight: bold;
  }
  
  .dashboard-cards {
    display: flex;
    flex-wrap: wrap;
    justify-content: center;
    gap: 20px;
  }
  
  .dashboard-card {
    background: #ffffff;
    color: #333;
    width: 200px;
    padding: 20px;
    border-radius: 10px;
    box-shadow: 0 4px 15px rgba(0, 0, 0, 0.1);
    text-align: center;
    transition: transform 0.3s ease, box-shadow 0.3s ease;
  }
  
  .dashboard-card:hover {
    transform: translateY(-5px);
    box-shadow: 0 8px 20px rgba(0, 0, 0, 0.2);
  }
  
  .dashboard-card .icon {
    font-size: 3rem;
    margin-bottom: 10px;
  }
  
  .dashboard-card h3 {
    font-size: 2rem;
    font-weight: bold;
    margin-bottom: 5px;
  }
  
  .dashboard-card p {
    font-size: 1rem;
    color: #555;
  }
  
  /* Responsive Design */
  @media (max-width: 768px) {
    .dashboard-cards {
      flex-direction: column;
      align-items: center;
    }
  
    .dashboard-card {
      width: 80%;
    }
  }
  
  @media (max-width: 480px) {
    .dashboard-title {
      font-size: 2rem;
    }
  }