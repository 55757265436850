/* General container styling */
.job-services-container {
    width: 90%;
    margin: 0 auto;
    font-family: Arial, sans-serif;
  }
  
  .job-services-container h1 {
    text-align: center;
    margin-bottom: 20px;
  }
  
  /* Actions section */
  .actions {
    display: flex;
    justify-content: space-between;
    align-items: center;
    margin-bottom: 20px;
  }
  
  .search-container {
    display: flex;
    align-items: center;
  }
  
  .search-container input {
    padding: 5px;
    font-size: 16px;
    border: 1px solid #ccc;
    border-radius: 5px;
    margin-right: 5px;
  }
  
  .search-button {
    background-color: #007bff;
    color: white;
    border: none;
    padding: 5px 10px;
    border-radius: 5px;
    cursor: pointer;
  }
  
  .search-button:hover {
    background-color: #0056b3;
  }
  
  .add-button {
    background-color: #28a745;
    color: white;
    border: none;
    padding: 5px 10px;
    border-radius: 5px;
    cursor: pointer;
  }
  
  .add-button:hover {
    background-color: #218838;
  }
  
  /* Form styling */
  .form-container {
    background-color: #f9f9f9;
    padding: 20px;
    border: 1px solid #ccc;
    border-radius: 10px;
    margin-bottom: 20px;
    max-width: 600px;
    width: 100%;
    box-sizing: border-box;
  }
  
  .form-container input,
  .form-container textarea {
    width: 100%;
    padding: 10px;
    margin-bottom: 10px;
    border: 1px solid #ccc;
    border-radius: 5px;
    font-size: 16px;
  }
  
  .form-submit-button {
    background-color: #007bff;
    color: white;
    border: none;
    padding: 12px;
    border-radius: 5px;
    cursor: pointer;
    width: 48%;
  }
  
  .form-submit-button:hover {
    background-color: #0056b3;
  }
  
  .cancel-button {
    background-color: #f8f9fa;
    color: #007bff;
    border: 1px solid #007bff;
    padding: 12px;
    border-radius: 5px;
    cursor: pointer;
    width: 48%;
  }
  
  .cancel-button:hover {
    background-color: #e2e6ea;
    color: #0056b3;
    border-color: #0056b3;
  }
  
  /* Table styling */
  table {
    width: 100%;
    border-collapse: collapse;
    margin-bottom: 20px;
  }
  
  table th,
  table td {
    border: 1px solid #ccc;
    padding: 10px;
    text-align: left;
  }
  
  table th {
    background-color: #f2f2f2;
  }
  
  .edit-button,
  .delete-button {
    background-color: #007bff;
    color: white;
    border: none;
    padding: 5px 10px;
    border-radius: 5px;
    cursor: pointer;
    margin-right: 5px;
  }
  
  .edit-button:hover {
    background-color: #0056b3;
  }
  
  .delete-button {
    background-color: #dc3545;
  }
  
  .delete-button:hover {
    background-color: #c82333;
  }
  
  /* Pagination styling */
  .pagination {
    display: flex;
    justify-content: center;
    align-items: center;
    gap: 10px;
  }
  
  .pagination button {
    background-color: #007bff;
    color: white;
    border: none;
    padding: 5px 10px;
    border-radius: 5px;
    cursor: pointer;
  }
  
  .pagination button:disabled {
    background-color: #ccc;
    cursor: not-allowed;
  }
  
  .pagination button:hover:not(:disabled) {
    background-color: #0056b3;
  }
  
  .pagination span {
    font-size: 16px;
  }

  select {
    padding: 10px;
    font-size: 16px;
    border: 1px solid #ccc;
    border-radius: 5px;
    width: 100%;
  }

  .page-size-selector {
    display: flex;
    align-items: center;
    gap: 5px;
  }
  
  .page-size-label {
    font-size: 14px;
    color: #333;
  }
  
  .page-size-dropdown {
    padding: 5px;
    font-size: 14px;
    border: 1px solid #ccc;
    border-radius: 5px;
    width: auto;
  }
  
  .validation-error {
    color: red;
    font-size: 14px;
    margin-top: -5px;
    margin-bottom: 10px;
  }
  
  