/* ======= Global Styles ======= */
:root {
  --primary-bg: #f4f7fc;
  --secondary-bg: #ffffff;
  --primary-text: #2c3e50;
  --secondary-text: #34495e;
  --border-color: #ddd;
  --shadow-light: 0 4px 12px rgba(0, 0, 0, 0.1);
  --shadow-hover: 0 6px 18px rgba(0, 0, 0, 0.15);
  --transition-fast: 0.3s ease-in-out;
  --radius: 12px;
}

/* Dark Mode Support */
@media (prefers-color-scheme: dark) {
  :root {
    --primary-bg: #1e1e1e;
    --secondary-bg: #292929;
    --primary-text: #ffffff;
    --secondary-text: #c0c0c0;
    --border-color: #444;
    --shadow-light: 0 4px 12px rgba(255, 255, 255, 0.1);
  }
}

body {
  font-family: 'Inter', sans-serif;
  background: var(--primary-bg);
  color: var(--primary-text);
  margin: 0;
  padding: 0;
}

/* ======= Admin Dashboard Container ======= */
.admin-dashboard-container {
  background: var(--secondary-bg);
  padding: 30px;
  max-width: 1200px;
  margin: 20px auto;
  border-radius: var(--radius);
  box-shadow: var(--shadow-light);
  transition: var(--transition-fast);
}

.admin-dashboard-container:hover {
  box-shadow: var(--shadow-hover);
}

/* ======= Headings ======= */
h1 {
  text-align: center;
  font-size: 34px;
  color: var(--primary-text);
  margin-bottom: 25px;
  font-weight: bold;
}

h2 {
  font-size: 24px;
  color: var(--secondary-text);
  margin-top: 40px;
  font-weight: 600;
}

/* ======= Loader & Error Messages ======= */
.loading,
.error {
  text-align: center;
  font-size: 16px;
  margin: 20px 0;
  padding: 12px;
  border-radius: var(--radius);
}

.loading {
  color: #0078d4;
  background-color: rgba(0, 120, 212, 0.1);
}

.error {
  color: #dc3545;
  background-color: rgba(220, 53, 69, 0.1);
}

/* ======= Admin Controls ======= */
.admin-controls {
  display: flex;
  flex-wrap: wrap;
  justify-content: space-between;
  align-items: center;
  gap: 20px;
  padding: 20px;
  background: var(--secondary-bg);
  border-radius: var(--radius);
  box-shadow: var(--shadow-light);
}

.admin-controls .controls-left {
  display: flex;
  flex-wrap: wrap;
  gap: 15px;
  align-items: center;
}

.admin-controls label {
  font-size: 14px;
  font-weight: 600;
  color: var(--secondary-text);
}

.admin-controls select,
.admin-controls input[type='date'] {
  padding: 8px 12px;
  border: 1px solid var(--border-color);
  border-radius: 6px;
  font-size: 14px;
  transition: var(--transition-fast);
}

.admin-controls select:hover,
.admin-controls input[type='date']:hover {
  border-color: #0078d4;
}

/* ======= Buttons ======= */
button {

  align-items: center;
  gap: 8px;
  padding: 10px 16px;
  font-size: 14px;
  font-weight: 600;
  border: none;
  border-radius: 8px;
  cursor: pointer;
  transition: var(--transition-fast);
}

button:hover {
  transform: scale(1.05);
  box-shadow: var(--shadow-hover);
}

button.csv {
  background: #28a745;
  color: white;
}

button.pdf {
  background: #dc3545;
  color: white;
}

button.excel {
  background: #0078d4;
  color: white;
}

button.json {
  background: #ffc107;
  color: #333;
}

/* ======= Column Selector ======= */
.column-selector {
  margin-top: 20px;
  background: var(--secondary-bg);
  border: 1px solid var(--border-color);
  border-radius: var(--radius);
  padding: 20px;
  box-shadow: var(--shadow-light);
}

.column-selector h4 {
  font-size: 18px;
  font-weight: bold;
}

.column-actions {
  display: flex;
  gap: 10px;
  margin-bottom: 15px;
}

.column-actions button {
  padding: 8px 12px;
  border-radius: 6px;
  cursor: pointer;
}

.column-actions .select-all {
  background: #28a745;
  color: white;
}

.column-actions .deselect-all {
  background: #dc3545;
  color: white;
}

.column-list {
  display: grid;
  grid-template-columns: repeat(auto-fit, minmax(150px, 1fr));
  gap: 12px;
}

/* ======= Data Table ======= */
.data-table-container {
  margin-top: 30px;
  border-radius: var(--radius);
  overflow-x: auto;
  background: var(--secondary-bg);
  box-shadow: var(--shadow-light);
}

.data-table {
  width: 100%;
  border-collapse: collapse;
}

.data-table th,
.data-table td {
  border: 1px solid var(--border-color);
  padding: 12px;
  text-align: center;
  font-size: 14px;
}

.data-table th {
  background: #f4f4f4;
  font-weight: bold;
  text-transform: capitalize;
}

.data-table tbody tr:nth-child(even) {
  background: rgba(0, 0, 0, 0.02);
}

.data-table tbody tr:hover {
  background: rgba(52, 152, 219, 0.1);
  transition: var(--transition-fast);
}

/* ======= Graph Display ======= */
.graph-display-container {
  margin-top: 30px;
  display: flex;
  flex-wrap: wrap;
  gap: 25px;
  justify-content: center;
}

.graph-item {
  flex: 1 1 45%;
  min-width: 300px;
  padding: 20px;
  border: 1px solid var(--border-color);
  border-radius: var(--radius);
  background: var(--secondary-bg);
  text-align: center;
  box-shadow: var(--shadow-light);
}

.graph-item h3 {
  font-size: 18px;
  font-weight: bold;
  color: var(--primary-text);
}

.graph-canvas {
  height: 350px;
  max-width: 100%;
}

/* ======= Responsive Design ======= */
@media (max-width: 768px) {
  .admin-controls {
    flex-direction: column;
    align-items: stretch;
  }

  .graph-item {
    flex: 1 1 100%;
  }

  .column-list {
    grid-template-columns: repeat(auto-fit, minmax(100px, 1fr));
  }

  .data-table th,
  .data-table td {
    font-size: 12px;
    padding: 8px;
  }
}
.column-selector {
  border: 1px solid #ddd;
  padding: 15px;
  border-radius: 8px;
  background: #f9f9f9;
  margin-bottom: 15px;
}

.column-selector h4 {
  margin-bottom: 10px;
  font-size: 16px;
  font-weight: bold;
}

.column-selector input[type="text"] {
  width: 100%;
  padding: 8px;
  font-size: 14px;
  margin-bottom: 10px;
  border: 1px solid #ccc;
  border-radius: 5px;
}

.column-list {
  max-height: 200px;
  overflow-y: auto;
  border: 1px solid #ddd;
  padding: 8px;
  background: white;
  border-radius: 5px;
}

.column-item {
  display: flex;
  align-items: center;
  gap: 8px;
  margin-bottom: 5px;
}

.column-actions {
  display: flex;
  justify-content: space-between;
  margin-top: 10px;
}

.select-all, .deselect-all, .apply-button {
  padding: 6px 12px;
  border: none;
  font-size: 14px;
  cursor: pointer;
  border-radius: 5px;
}

.select-all {
  background-color: #007bff;
  color: white;
}

.deselect-all {
  background-color: #ff4d4d;
  color: white;
}

.apply-button {
  background-color: #28a745;
  color: white;
}

.no-results {
  font-size: 14px;
  color: red;
  text-align: center;
}
