/* 🔹 General Page Container */
.container {
  max-width: 1200px;
  margin: auto;
  padding: 1rem;
}

/* 🔹 SEARCH BAR STYLING */
.search-bar-container {
  display: flex;
  justify-content: center;
  width: 100%;
  margin-bottom: 1.5rem;
}

.search-input-wrapper {
  display: flex;
  align-items: center;
  background: #fff;
  padding: 12px;
  border: 2px solid #ccc;
  border-radius: 8px;
  width: 100%;
  max-width: 600px;
  transition: border 0.3s ease-in-out;
  box-shadow: 2px 2px 8px rgba(0, 0, 0, 0.1);
}

.search-icon {
  color: #457b9d;
  font-size: 1.2rem;
  margin-right: 10px;
}

.search-input {
  border: none;
  flex: 1;
  font-size: 1rem;
  padding: 8px;
  outline: none;
}

.search-input:focus {
  border-color: #457b9d;
}

/* 🔹 FILTER PANEL */
.filter-panel {
  background: #ffffff;
  padding: 1rem;
  border-radius: 12px;
  border: 1px solid #ddd;
  box-shadow: 2px 4px 10px rgba(0, 0, 0, 0.1);
  width: 280px;
  transition: all 0.3s ease-in-out;
}

/* 🔹 FILTER HEADER */
.filter-header {
  display: flex;
  align-items: center;
  gap: 10px;
  padding: 12px;
  border-radius: 8px;
  background-color: #457b9d;
  color: #fff;
  font-size: 1.2rem;
  font-weight: bold;
  transition: background 0.3s ease;
}

.filter-header:hover {
  background-color: #305f7e;
}

.filter-icon-large {
  font-size: 1.5rem;
}

/* 🔹 FILTER CONTENT */
.filter-content {
  margin-top: 10px;
  padding: 10px;
  background: #f8f9fa;
  border-radius: 8px;
}

/* 🔹 FILTER ITEM */
.filter-item {
  display: flex;
  align-items: center;
  gap: 12px;
  padding: 10px;
  border-radius: 8px;
  background: #ffffff;
  box-shadow: 2px 2px 5px rgba(0, 0, 0, 0.1);
  margin-bottom: 10px;
  transition: transform 0.2s ease-in-out;
}

.filter-item:hover {
  background-color: #e3f2fd;
  transform: scale(1.02);
}

/* 🔹 FILTER ICON */
.filter-icon {
  color: #457b9d;
  font-size: 1.2rem;
}

/* 🔹 CHECKBOX STYLING */
.filter-item input[type="checkbox"] {
  margin-right: 10px;
  transform: scale(1.2);
  cursor: pointer;
}

/* 🔹 RESPONSIVE DESIGN */
@media (max-width: 768px) {
  .content-row {
      flex-direction: column;
      gap: 1rem;
  }

  .filter-panel {
      width: 100%;
  }

  .search-input-wrapper {
      width: 90%;
  }
}
