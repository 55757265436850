.project-delivery {
  padding: 60px 20px;
  background: #f9fafc;
  text-align: center;
}

.section-title {
  font-size: 2.5rem;
  color: #007bff;
  margin-bottom: 20px;
  font-weight: bold;
}

.section-subtitle {
  font-size: 1.1rem;
  color: #666;
  margin-bottom: 40px;
}

.project-steps {
  display: grid;
  grid-template-columns: repeat(auto-fit, minmax(250px, 1fr));
  gap: 20px;
  justify-items: center;
}

.project-step {
  background: #ffffff;
  padding: 20px;
  border-radius: 10px;
  box-shadow: 0 4px 10px rgba(0, 0, 0, 0.1);
  text-align: center;
  transition: transform 0.3s ease, box-shadow 0.3s ease;
  max-width: 300px;
}

.project-step:hover {
  transform: translateY(-5px);
  box-shadow: 0 8px 20px rgba(0, 0, 0, 0.2);
}

.step-image {
  width: 100%;
  height: 150px;
  margin-bottom: 10px;
  border-radius: 8px;
  overflow: hidden;
}

.step-image img {
  width: 100%;
  height: 100%;
  object-fit: cover;
}

.step-icon {
  font-size: 3rem;
  color: #007bff;
  margin-bottom: 10px;
}

.step-title {
  font-size: 1.2rem;
  font-weight: bold;
  margin-bottom: 10px;
}

.step-description {
  font-size: 1rem;
  color: #555;
  line-height: 1.5;
}

/* Responsive Design */
@media (max-width: 768px) {
  .project-steps {
    grid-template-columns: 1fr 1fr;
  }

  .section-title {
    font-size: 2rem;
  }

  .section-subtitle {
    font-size: 1rem;
  }
}

@media (max-width: 480px) {
  .project-steps {
    grid-template-columns: 1fr;
  }

  .project-step {
    max-width: 90%;
  }

  .step-title {
    font-size: 1.1rem;
  }

  .step-description {
    font-size: 0.9rem;
  }
}
