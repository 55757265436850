/* ========================= */
/* General Container Styles */
/* ========================= */
.blogs-container {
  padding: 20px;
  font-family: 'Arial', sans-serif;
}

/* ========================= */
/* Header Section */
/* ========================= */
.header {
  display: flex;
  justify-content: space-between;
  align-items: center;
  flex-wrap: wrap;
  margin-bottom: 20px;
}

.search-bar {
  display: flex;
  align-items: center;
  gap: 10px;
  flex: 1;
}

.search-bar input {
  flex: 1;
  padding: 10px;
  border-radius: 4px;
  border: 1px solid #ccc;
  max-width: 300px;
}

.search-btn {
  background-color: #4caf50;
  color: white;
  padding: 10px 14px;
  border-radius: 4px;
  font-size: 14px;
  border: none;
  cursor: pointer;
  transition: background 0.3s ease-in-out;
}

.search-btn:hover {
  background-color: #388e3c;
}

.add-btn {
  background-color: #f39c12;
  color: white;
  padding: 8px 12px;
  border-radius: 4px;
  font-size: 14px;
  border: none;
  cursor: pointer;
  transition: background 0.3s ease-in-out;
}

.add-btn:hover {
  background-color: #e67e22;
}

/* ========================= */
/* Form Modal Styles */
/* ========================= */
.form-modal-overlay {
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background: rgba(0, 0, 0, 0.5);
  display: flex;
  justify-content: center;
  align-items: center;
  z-index: 1000;
  overflow-y: auto;
}

.form-modal {
  background: white;
  padding: 20px;
  border-radius: 8px;
  width: 400px;
  max-width: 90%;
  max-height: 80%;
  overflow-y: auto;
  box-shadow: 0 4px 8px rgba(0, 0, 0, 0.2);
  display: flex;
  flex-direction: column;
}

.form-header {
  display: flex;
  justify-content: space-between;
  align-items: center;
  margin-bottom: 15px;
}

.form-header h3 {
  margin: 0;
}

.close-btn {
  background: transparent;
  border: none;
  font-size: 20px;
  cursor: pointer;
}

/* Form Fields */
label {
  font-weight: bold;
  margin-top: 15px;
  display: block;
}

input,
textarea,
select {
  margin-top: 5px;
  margin-bottom: 15px;
  padding: 10px;
  border-radius: 4px;
  border: 1px solid #ccc;
  box-sizing: border-box;
  width: 100%;
}

textarea {
  resize: vertical;
  height: 150px; /* Proper height for add/edit form */
}

/* Image Upload Preview */
.preview-image {
  display: block;
  margin: 10px 0;
  width: 100%;
  max-height: 200px;
  object-fit: cover;
  border: 1px solid #ccc;
  border-radius: 4px;
}

/* Form Buttons */
.form-buttons {
  display: flex;
  gap: 10px;
  margin-top: 15px;
  padding-top: 10px;
  background: #fff;
  position: sticky;
  bottom: 0;
  border-top: 1px solid #ddd;
  padding: 10px 0;
}

.submit-btn {
  background-color: #0078d7;
  color: white;
  padding: 10px 15px;
  border-radius: 4px;
  border: none;
  cursor: pointer;
  font-size: 14px;
  transition: background 0.3s ease-in-out;
}

.submit-btn:hover {
  background-color: #005bb5;
}

.cancel-btn {
  background-color: #ff4757;
  color: white;
  padding: 10px 15px;
  border-radius: 4px;
  border: none;
  cursor: pointer;
  font-size: 14px;
  transition: background 0.3s ease-in-out;
}

.cancel-btn:hover {
  background-color: #e84118;
}

/* ========================= */
/* Blog Cards */
/* ========================= */
.blog-cards {
  display: grid;
  grid-template-columns: repeat(auto-fit, minmax(250px, 1fr));
  gap: 15px;
  margin-top: 20px;
}

.blog-card {
  background: #fff;
  border: 1px solid #ddd;
  padding: 15px;
  border-radius: 8px;
  text-align: center;
  box-shadow: 0 2px 5px rgba(0, 0, 0, 0.1);
  overflow: hidden;
  transition: transform 0.3s ease-in-out, box-shadow 0.3s ease-in-out;
}

.blog-card:hover {
  transform: translateY(-5px);
  box-shadow: 0 4px 8px rgba(0, 0, 0, 0.15);
}

.blog-card img {
  width: 100%;
  height: 150px;
  object-fit: cover;
  border-radius: 4px;
  margin-bottom: 10px;
}

.blog-card h3 {
  font-size: 18px;
  color: #333;
  margin-bottom: 5px;
}

.blog-card p {
  font-size: 14px;
  color: #666;
  margin-bottom: 10px;
}

.card-actions {
  margin-top: 10px;
  display: flex;
  justify-content: center;
  gap: 10px;
}

.card-actions button {
  padding: 6px 10px;
  border-radius: 4px;
  font-size: 12px;
  border: none;
  cursor: pointer;
  transition: background 0.3s ease-in-out;
}

.edit-btn {
  background: #ffc107;
  color: white;
}

.edit-btn:hover {
  background: #e0a800;
}

.delete-btn {
  background: #dc3545;
  color: white;
}

.delete-btn:hover {
  background: #c82333;
}

/* ========================= */
/* Responsive Design */
/* ========================= */

/* Tablet View */
@media (max-width: 768px) {
  .header {
    flex-direction: column;
    align-items: flex-start;
  }

  .search-bar {
    width: 100%;
    margin-bottom: 10px;
  }

  .add-btn {
    margin-left: 0;
  }

  .form-modal {
    width: 90%;
  }
}

/* Mobile View */
@media (max-width: 480px) {
  .search-bar {
    flex-direction: column;
    align-items: stretch;
  }

  .search-bar input {
    width: 100%;
  }

  .form-modal {
    width: 95%;
  }

  .blog-cards {
    grid-template-columns: 1fr;
  }

  .form-buttons {
    flex-wrap: wrap;
    justify-content: center;
  }
}

/* ========================= */
/* Accessibility Improvements */
/* ========================= */
input:focus,
textarea:focus,
select:focus,
button:focus {
  outline: 2px solid #0078d7;
}

